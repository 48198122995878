import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { FagmoblerOmniumCart } from '@fagmobler/types';
import type {
  OmniumShippingOption,
  OmniumPaymentOption,
} from '@fagmobler/omnium';
import { HOST } from '@/lib/host';

export const emptyCart: FagmoblerOmniumCart = {
  id: null,
  storeId: null,
};

export async function setStoreId(
  storeId: FagmoblerOmniumCart['storeId']
): Promise<FagmoblerOmniumCart> {
  const res = await fetch(`${HOST}/api/cart/store`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ storeId }),
  });

  if (res.status !== 200) return emptyCart;

  const data = (await res.json()) as FagmoblerOmniumCart;

  return data;
}

export async function fetchShippingOptions(): Promise<OmniumShippingOption[]> {
  const res = await fetch(`${HOST}/api/cart/shipping-options`);

  if (res.status !== 200) return [];
  // console.log('shippingOptions', { res });

  const data = (await res.json()) as OmniumShippingOption[];
  // console.log('shippingOptions', { data });

  return data;
}

export async function fetchPaymentOptions(): Promise<OmniumPaymentOption[]> {
  const res = await fetch(`${HOST}/api/cart/payment-options`);

  if (res.status !== 200) return [];
  // console.log('paymentOptions', { res });

  const data = (await res.json()) as OmniumPaymentOption[];
  // console.log('paymentOptions', { data });

  return data;
}

export async function fetchCart(): Promise<FagmoblerOmniumCart> {
  const res = await fetch(`${HOST}/api/cart`);
  // console.log({ res });

  if (res.status !== 200) {
    // throw new Error('Could not get cart');
    return emptyCart;
  }

  const data = (await res.json()) as FagmoblerOmniumCart;
  // console.log('got cart', data);

  return data;
}

export type UseCartInitialData = {
  initialData?: {
    cart?: FagmoblerOmniumCart;
    shippingOptions?: OmniumShippingOption[];
    paymentOptions?: OmniumPaymentOption[];
  };
};

export function useCart(
  {
    initialData: { cart = emptyCart, shippingOptions, paymentOptions } = {},
  }: UseCartInitialData = { initialData: {} }
) {
  const queryClient = useQueryClient();

  return {
    cart: useQuery<FagmoblerOmniumCart>('cart', () => fetchCart(), {
      initialData: cart,
    }),
    shippingOptions: useQuery<OmniumShippingOption[]>(
      'shippingOptions',
      () => [],
      // () => fetchShippingOptions(),
      { initialData: shippingOptions }
    ),
    paymentOptions: useQuery<OmniumPaymentOption[]>(
      'paymentOptions',
      () => [],
      // () => fetchPaymentOptions(),
      { initialData: paymentOptions }
    ),
    setStore: useMutation<
      FagmoblerOmniumCart,
      FagmoblerOmniumCart,
      FagmoblerOmniumCart['storeId']
    >((storeId) => setStoreId(storeId), {
      onMutate: async (storeId) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['cart']);

        // Snapshot the previous value
        const oldCart = queryClient.getQueryData<FagmoblerOmniumCart>(['cart']);

        // Optimistically update to the new value
        const newCart = queryClient.setQueryData<FagmoblerOmniumCart>(
          ['cart'],
          (old) => ({ ...old, storeId })
        );

        // Return a context with the previous and new
        return { oldCart };
      },
      onSuccess: (newCart) => {
        // ✅ update detail view directly
        queryClient.setQueryData('cart', { ...newCart });
      },
    }),
  };
}
