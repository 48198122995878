import React from 'react';

function PintrestLogo({ className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 25"
      className={className}
    >
      <path
        fill="#fff"
        d="M5.077 9.705c0-.778.136-1.513.404-2.199a5.63 5.63 0 011.121-1.802 7.614 7.614 0 011.644-1.329 7.514 7.514 0 012.002-.844 8.57 8.57 0 012.185-.281c1.14 0 2.2.241 3.182.721a6.02 6.02 0 012.391 2.094c.614.915.92 1.95.92 3.104 0 .692-.069 1.369-.208 2.031a8.28 8.28 0 01-.646 1.913 6.605 6.605 0 01-1.082 1.617c-.44.475-.974.854-1.568 1.114a4.961 4.961 0 01-2.045.417c-.489 0-.977-.115-1.459-.346-.482-.23-.828-.546-1.036-.951-.073.281-.173.687-.306 1.218-.128.53-.214.872-.252 1.027-.04.154-.114.411-.222.767-.073.263-.167.52-.28.769l-.345.674a7.98 7.98 0 01-.498.838c-.18.262-.405.575-.672.935l-.149.053-.099-.108c-.107-1.133-.162-1.811-.162-2.035 0-.663.08-1.407.235-2.233.153-.825.395-1.862.72-3.109.325-1.246.511-1.979.561-2.196-.229-.467-.345-1.077-.345-1.827 0-.599.187-1.16.562-1.688.376-.526.851-.789 1.427-.789.441 0 .783.146 1.028.439.246.292.366.66.366 1.109 0 .476-.158 1.165-.476 2.066-.318.902-.476 1.575-.476 2.022 0 .453.162.832.486 1.129.32.297.743.458 1.18.449.395 0 .762-.09 1.103-.271a2.46 2.46 0 00.85-.733 6.125 6.125 0 001.016-2.225c.096-.422.17-.823.216-1.2a8.2 8.2 0 00.07-1.077c0-1.247-.396-2.219-1.183-2.915-.79-.696-1.82-1.042-3.088-1.042-1.44 0-2.646.466-3.61 1.401-.967.932-1.453 2.117-1.453 3.554 0 .317.048.623.14.919.088.295.185.53.29.704.104.171.202.338.291.492.09.154.137.264.137.33 0 .202-.053.465-.16.79-.11.325-.242.487-.4.487-.015 0-.077-.011-.185-.034a2.21 2.21 0 01-.979-.605 3.17 3.17 0 01-.659-1.022 6.983 6.983 0 01-.352-1.169 4.884 4.884 0 01-.132-1.153z"
      ></path>
    </svg>
  );
}

export default PintrestLogo;
