import clsx from 'clsx';
import { MapPinIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

type RegionOptionProps = {
  label: string;
  onSelect: () => void;
};

export function RegionOption({ label, onSelect }: RegionOptionProps) {
  return (
    <button
      onClick={onSelect}
      className={clsx(
        'relative w-full bg-white',
        'flex gap-4 justify-between',
        'p-4 mt-2',
        'hd-md text-left',
        'border-b border-b-neutral-40',
        'hover:underline underline-offset-2',
      )}
    >
      <MapPinIcon className="w-6 h-6" />
      <span className="flex-grow">
        {label}
      </span>
      <ChevronRightIcon className="w-6 h-6" />
    </button>
  );
}

export default RegionOption;
