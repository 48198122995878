import { Suspense } from 'react';
import dynamic from 'next/dynamic';
import { DrawerModal } from '@fagmobler/ui';

import { useNavigation } from '@/contexts/navigation';

const PurchaseTerms = dynamic(() => import('./PurchaseTerms'));

export function PurchaseTermsDrawer() {
  const { showPurchaseTerms, setShowPurchaseTerms } = useNavigation();

  return (
    <DrawerModal
      isOpen={showPurchaseTerms}
      onClose={() => setShowPurchaseTerms(false)}
      title="Vilkår for kjøp"
      unmount={true}
    >
      <Suspense fallback={`Loading...`}>
        <PurchaseTerms />
      </Suspense>
    </DrawerModal>
  );
}

export default PurchaseTermsDrawer;
