import { useEffect, useRef, useCallback, useState } from 'react';
import NextImage from 'next/image';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Portal } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { TImage } from '.';
import clsx from 'clsx';
import { IconButton } from '@fagmobler/ui';

export type LightboxProps = {
  images: TImage[];
  currentImage: TImage;
  onChangeImage(image: TImage): void;
  children?: never;
  onClose(): void;
};

export const Lightbox = ({
  images,
  onClose,
  currentImage,
  onChangeImage,
}: LightboxProps) => {
  const scrollContainerRef = useRef<HTMLUListElement | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scroll({
        top: 0,
        left:
          window.innerWidth *
          images.findIndex((image) => image === currentImage),
        behavior: firstLoad ? undefined : 'smooth',
      });
    }
    setFirstLoad(false);
  }, [currentImage, images, firstLoad]);

  const handleNextImage = useCallback(() => {
    const nextImageIndex =
      (images?.findIndex((image) => image === currentImage) || 0) + 1;
    if (images[nextImageIndex]) {
      onChangeImage(images[nextImageIndex]);
    }
  }, [currentImage, images, onChangeImage]);

  const handlePrevImage = useCallback(() => {
    const nextImageIndex =
      (images?.findIndex((image) => image === currentImage) || 0) - 1;
    if (images[nextImageIndex]) {
      onChangeImage(images[nextImageIndex]);
    }
  }, [currentImage, images, onChangeImage]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') handleNextImage();
      if (event.key === 'ArrowLeft') handlePrevImage();
      if (event.key === 'Escape') onClose();
    },
    [handlePrevImage, handleNextImage, onClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Portal refName="lightbox">
        <div className="fixed top-0 left-0 w-full h-full z-50 overflow-hidden bg-neutral-0">
          {/* <div className="absolute top-0 l-0 w-screen h-screen bg-white opacity-90" /> */}
          <ul
            className="relative w-full top-0 overflow-scroll flex flex-row flex-nowrap snap-x"
            style={{ maxHeight: 'calc(100%-30rem' }}
            ref={scrollContainerRef}
          >
            {images?.map((image) => (
              <li
                key={`main${image.key}`}
                className="flex h-screen w-screen min-w-full p-10 snap-center items-center justify-center relative "
              >
                <div className="m-2 relative w-full h-[calc(100vh-20rem)]">
                  <NextImage
                    src={image.url}
                    fill
                    alt={image.alt || 'Bilde'}
                    className="p-3 w-auto mix-blend-darken object-contain"
                  />
                </div>
              </li>
            ))}
          </ul>
          <ul
            className={clsx(
              'absolute',
              'w-full',
              'bottom-10',
              'h-20',
              'flex flex-row flex-nowrap',
              'overflow-x-scroll',
              'justify-center',
              'py-3',
              'z-30',
              'box-content',
              'scrollbar-hide'
            )}
          >
            <li>
              <button
                onClick={handlePrevImage}
                className="flex justify-center items-center h-full w-8"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-5" />
              </button>
            </li>
            {images?.map((image) => (
              <li
                key={image.key}
                className={clsx(
                  'bg-neutral-30',
                  'w-20 h-20',
                  'relative',
                  'mr-5',
                  'rounded',
                  'box-border',
                  'flex justify-center items-center',
                  {
                    'outline outline-2 outline-offset-4 outline-neutral-60':
                      currentImage === image,
                  }
                )}
              >
                <a
                  href={image.url}
                  onClick={(event) => {
                    onChangeImage(image);
                    event.preventDefault();
                  }}
                  className={clsx(
                    'flex justify-center items-center',
                    'relative',
                    'w-16 h-16'
                  )}
                >
                  <NextImage
                    className="mix-blend-darken object-contain"
                    src={image.url}
                    width={64}
                    height={64}
                    alt={image.alt || 'Bilde'}
                  />
                </a>
              </li>
            ))}
            <li>
              <button
                onClick={handleNextImage}
                className="flex justify-center items-center h-full w-8"
              >
                <ArrowRightIcon className="h-5 w-5" />
              </button>
            </li>
          </ul>
          <IconButton
            title="Lukk"
            size="lg"
            className="flex top-5 right-5 absolute z-30 bg-neutral-80 text-neutral-0"
            onClick={onClose}
          >
            <XMarkIcon className="w-5 h-5" />
          </IconButton>
        </div>
      </Portal>
    </>
  );
};
export default Lightbox;
