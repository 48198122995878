import {
  UseQueryOptions,
  UseQueryResult,
  useQueries,
  useQueryClient,
} from 'react-query';
import { HOST } from '@/lib/host';
import {
  FagmoblerPublicOmniumProduct,
  FagmoblerInventory,
  FagmoblerAlgoliaProduct,
} from '@fagmobler/types';

type Product = FagmoblerPublicOmniumProduct | FagmoblerAlgoliaProduct;

type UseInventoryProps = {
  products: Product[];
};

export async function fetchProductInventory(
  sku: string
): Promise<FagmoblerInventory> {
  const url = new URL(`${HOST}/api/inventory`);
  url.searchParams.set('sku', sku);
  const res = await fetch(url);

  if (res.status !== 200) {
    throw new Error('Could not fetch inventory');
  }

  const data = (await res.json()) as FagmoblerInventory;

  return data;
}

export function useInventory(
  { products }: UseInventoryProps = { products: [] }
): UseQueryResult<FagmoblerInventory, unknown>[] {
  useQueryClient();
  return useQueries(
    products.map((product) => {
      let sku = 'objectID' in product ? product.objectID : product.skuId;
      return {
        queryKey: `inventory_${sku}`,
        queryFn: () => fetchProductInventory(sku),
        useErrorBoundary: true,
        onError: (error: unknown) => {
          console.error('Handle error', error);
        },
        staleTime: 1000 * 60 * 5, // 5 Minutes
        enabled: !!sku,
      };
    })
  );
}

export const getInventoryData = (
  inventoryResult: UseQueryResult<FagmoblerInventory, unknown>[]
) =>
  inventoryResult
    .map((result) => result.data)
    .reduce((acc, data) => {
      return {
        ...acc,
        ...data,
      };
    }, {} as FagmoblerInventory) || {};
