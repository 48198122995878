import { useMemo } from 'react';

import type { OmniumStore } from '@fagmobler/omnium';
import type { Region } from './useStores';

import { regions as noRegions } from '@/lib/no-regions';

const noRegionKeys = Object.keys(noRegions);

export interface RegionWithStores<T> extends Region {
  stores: T[];
}

export type StoresByRegion<T> = Record<string, RegionWithStores<T>>;

export function useStoresByRegion<T extends OmniumStore>(
  stores: T[]
): StoresByRegion<T> {
  return useMemo(
    () =>
      stores?.reduce((acc: StoresByRegion<T>, store) => {
        const storeRegion = store.externalIdsList?.find(
          ({ providerName }) => providerName === 'Fylke'
        );

        if (
          !storeRegion ||
          !storeRegion.id ||
          !noRegionKeys.includes(storeRegion.id)
        ) {
          return acc;
        }

        if (!acc[storeRegion.id]) {
          acc[storeRegion.id] = {
            ...noRegions[storeRegion.id as keyof typeof noRegions],
            stores: [],
          };
        }

        acc[storeRegion.id].stores.push(store);

        return acc;
      }, {}),
    [stores]
  );
}

export default useStoresByRegion;
