import { Suspense } from 'react';
import dynamic from 'next/dynamic';

import { DrawerModal } from '@fagmobler/ui';

import { useNavigation } from '@/contexts/navigation';

const ClubMemberForm = dynamic(() => import('./ClubMemberForm'));

export function ClubMemberDrawer() {
  const { showClubMemberForm, setShowClubMemberForm } = useNavigation();

  return (
    <DrawerModal
      isOpen={showClubMemberForm}
      onClose={() => setShowClubMemberForm(false)}
      title="Meld deg inn i kundeklubben"
      unmount={true}
    >
      <Suspense fallback={`Loading...`}>
        <ClubMemberForm />
      </Suspense>
    </DrawerModal>
  );
}

export default ClubMemberDrawer;
