import { OmniumStore } from '@fagmobler/omnium';
import type {
  FagmoblerInventory,
  FagmoblerPublicOmniumProduct,
} from '@fagmobler/types';

export enum NavigationActionTypes {
  TOGGLE_MAIN_NAVIGATION = 'TOGGLE_MAIN_NAVIGATION',
  TOGGLE_CART = 'TOGGLE_CART',
  TOGGLE_SEARCH = 'TOGGLE_SEARCH',
  TOGGLE_STORES = 'TOGGLE_STORES',
  TOGGLE_MEMBER_FORM = 'TOGGLE_MEMBER_FORM',
  TOGGLE_MEMBER_TERMS = 'TOGGLE_MEMBER_TERMS',
  TOGGLE_PURCHASE_TERMS = 'TOGGLE_PURCHASE_TERMS',
  TOGGLE_STORE_STOCK = 'TOGGLE_STORE_STOCK',
  TOGGLE_CLICK_AND_COLLECT = 'TOGGLE_CLICK_AND_COLLECT',
}

export type NavigationAction =
  | { type: NavigationActionTypes.TOGGLE_CART; value?: boolean }
  | { type: NavigationActionTypes.TOGGLE_SEARCH; value?: boolean }
  | { type: NavigationActionTypes.TOGGLE_STORES; value?: boolean }
  | { type: NavigationActionTypes.TOGGLE_MEMBER_FORM; value?: boolean }
  | { type: NavigationActionTypes.TOGGLE_MEMBER_TERMS; value?: boolean }
  | { type: NavigationActionTypes.TOGGLE_PURCHASE_TERMS; value?: boolean }
  | { type: NavigationActionTypes.TOGGLE_MAIN_NAVIGATION; value?: boolean }
  | {
      type: NavigationActionTypes.TOGGLE_STORE_STOCK;
      value?: {
        visible: boolean;
        selectedProduct?: FagmoblerPublicOmniumProduct | null;
        inventory: FagmoblerInventory | null;
        title?: string;
      };
    }
  | {
      type: NavigationActionTypes.TOGGLE_CLICK_AND_COLLECT;
      value?: {
        visible?: boolean;
        product?: FagmoblerPublicOmniumProduct | null;
        quantity?: number;
        stock?: {
          stock: number;
          display: number;
        };
        store?: OmniumStore | null;
      };
    };

export type NavigationDispatch = (action: NavigationAction) => void;

export type NavigationReducerState = {
  showCart: boolean;
  showSearch: boolean;
  showStores: boolean;
  showClubMemberForm: boolean;
  showMemberTerms: boolean;
  showPurchaseTerms: boolean;
  showStoreStock: {
    title?: string;
    visible: boolean;
  };
  showMainNavigation: boolean;
  selectedProduct: FagmoblerPublicOmniumProduct | null;
  inventory: FagmoblerInventory | null;
  clickAndCollect?: {
    visible?: boolean;
    product?: FagmoblerPublicOmniumProduct | null;
    quantity?: number;
    stock?: {
      stock: number;
      display: number;
    };
    store?: OmniumStore | null;
  };
};

export const initialState: NavigationReducerState = {
  showCart: false,
  showSearch: false,
  showStores: false,
  showClubMemberForm: false,
  showMemberTerms: false,
  showPurchaseTerms: false,
  showMainNavigation: false,
  showStoreStock: {
    visible: false,
  },
  selectedProduct: null,
  inventory: null,
  clickAndCollect: undefined,
};

export function navigationReducer(
  state: NavigationReducerState,
  { type, value }: NavigationAction
): NavigationReducerState {
  switch (type) {
    case NavigationActionTypes.TOGGLE_CART:
      return { ...state, showCart: value ?? !state.showCart };

    case NavigationActionTypes.TOGGLE_SEARCH:
      return { ...state, showSearch: value ?? !state.showSearch };

    case NavigationActionTypes.TOGGLE_MEMBER_FORM:
      return {
        ...state,
        showClubMemberForm: value ?? !state.showClubMemberForm,
      };

    case NavigationActionTypes.TOGGLE_MEMBER_TERMS:
      return { ...state, showMemberTerms: value ?? !state.showMemberTerms };

    case NavigationActionTypes.TOGGLE_PURCHASE_TERMS:
      return { ...state, showPurchaseTerms: value ?? !state.showPurchaseTerms };

    case NavigationActionTypes.TOGGLE_MAIN_NAVIGATION:
      return {
        ...state,
        showMainNavigation: value ?? !state.showMainNavigation,
      };

    case NavigationActionTypes.TOGGLE_STORES:
      return { ...state, showStores: value ?? !state.showStores };

    case NavigationActionTypes.TOGGLE_STORE_STOCK:
      return {
        ...state,
        showStoreStock: {
          visible: value?.visible ?? !state.showStoreStock,
          title: value?.title,
        },
        inventory: value?.inventory || null,
        selectedProduct:
          typeof value?.selectedProduct === 'undefined'
            ? state.selectedProduct
            : value?.selectedProduct,
      };

    case NavigationActionTypes.TOGGLE_CLICK_AND_COLLECT:
      return {
        ...state,
        clickAndCollect: value,
      };
    default:
      return state;
  }
}
