import { useRouter } from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button, Logo, StoreIcon } from '@fagmobler/ui';
import { useNavigation } from '@/contexts/navigation';
import { MobileNavigation } from './MobileNavigation/MobileNavigation';
import { useStores } from '@/components/store';
import { getStorePath } from '@/lib/omnium/getStoreUrl';
import { MegaMenu } from '@/components/layout/Header/MegaMenu';

import { DefaultWrapper } from '../DefaultWrapper';
import { CartButton } from '@/components/layout/CartButton';
import { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import MobileNavigationToggle from '@/components/layout/Header/MobileNavigation/MobileNavigationToggle';
import { HeaderSearch } from './search/HeaderSearch';

export const Header = () => {
  const router = useRouter();
  const { setShowStores } = useNavigation();
  const { current } = useStores();
  const store = current?.data?.store;
  const storePath = store ? getStorePath(store) : null;
  const [show, setShow] = useState(true);
  const pathname = usePathname();
  const isSearchPage = pathname === '/sok';
  const searchParams = useSearchParams();
  const route = searchParams.get('route') || '';

  useEffect(() => {
    let lastScrollPosition = 0;

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setShow(true);
        return;
      }
      const currentScrollPosition = window.scrollY;

      const scrollingUp = currentScrollPosition < lastScrollPosition;
      const showMenu =
        scrollingUp || (!scrollingUp && currentScrollPosition < 200);
      setShow(showMenu);
      lastScrollPosition = currentScrollPosition;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MobileNavigation />
      <Logo className="h-7 hidden print:block" />
      <div
        className={clsx(
          'fixed top-0 z-40 w-full left-0',
          'bg-neutral-80  text-neutral-0 print:text-neutral-80 print:bg-[transparent] print:static',
          'transition-transform duration-500 ease-in-out',
          {
            '-translate-y-full': !show,
            'translate-y-0': show,
          },
          'focus-within:!translate-y-0',
          'print:hidden'
        )}
      >
        <DefaultWrapper
          className={clsx(
            'flex items-center gap-2 py-4 flex-wrap lg:flex-nowrap',
            'print:block print:h-auto print:px-0 print:max-w-full'
          )}
        >
          {router && !router.pathname.includes('/utsjekk') && (
            <MobileNavigationToggle />
          )}

          {router && router.pathname === '/utsjekk' && (
            <button
              className="flex items-center rounded-full bg-neutral-70 py-3 pl-4 pr-6 cap-sm"
              onClick={router.back}
            >
              <ArrowLeftIcon className="w-4 h-4 text-neutral-0 mr-2" /> Avbryt
            </button>
          )}
          {router && router.pathname === '/utsjekk/bekreftelse' && (
            <Link legacyBehavior href="/" passHref>
              <a className="flex items-center rounded-full bg-neutral-70 py-3 pl-4 pr-6 cap-sm">
                <ArrowLeftIcon className="w-4 h-4 text-neutral-0 mr-2" />{' '}
                Tilbake
              </a>
            </Link>
          )}

          <Link
            href="/"
            title="Til fremsiden"
            className={clsx(
              'flex flex-grow lg:flex-grow-0 items-center',
              'text-2xl md:text-4xl md:mr-5',
              'print:block focus:outline-none ring-offset-focus focus-visible:ring-4'
            )}
          >
            <Logo className="h-7" />
          </Link>

          <HeaderSearch />

          {!isSearchPage && (
            <>
              {store && storePath ? (
                <Link
                  href={storePath}
                  className={clsx(
                    'flex items-center p-2 lg:px-5 rounded-full hover:bg-neutral-90 group ml-auto',
                    'focus:outline-none ring-offset-focus focus-visible:ring-4'
                  )}
                  title={`Valgt butikk: ${store.name}`}
                >
                  <StoreIcon className="w-5 h-5 mr-2" />
                  <span className="hidden sm:block group-hover:underline underline-offset-4">
                    {store.name?.replace('Fagmøbler ', '')}
                  </span>
                </Link>
              ) : (
                <button
                  onClick={() => setShowStores(true)}
                  className={clsx(
                    'flex items-center ml-auto',
                    'focus:outline-none ring-offset-focus focus-visible:ring-4'
                  )}
                >
                  <StoreIcon className="w-5 h-5 mr-2" />
                  <span className="hidden sm:block">Velg butikk</span>
                </button>
              )}
              {router && !router.pathname.includes('/utsjekk') && (
                <CartButton />
              )}
            </>
          )}
        </DefaultWrapper>

        <div className="border-t-1 border-neutral-70 hidden lg:block ">
          <DefaultWrapper>
            <div className="flex justify-between">
              <MegaMenu />
            </div>
          </DefaultWrapper>
        </div>
      </div>
    </>
  );
};

export default Header;
