import { Suspense } from 'react';
import dynamic from 'next/dynamic';

import { DefaultModal } from '@fagmobler/ui';

import { useNavigation } from '@/contexts/navigation';

const StoreLocator = dynamic(() => import('./StoreLocator'), { ssr: false });

export function StoreLocatorDrawer() {
  const { showStoreStock, setShowStoreStock } = useNavigation();

  return (
    <DefaultModal
      isOpen={showStoreStock?.visible}
      onClose={() => setShowStoreStock({ visible: false, inventory: null })}
      title={showStoreStock.title || 'Se lagerstatus for produkt'}
      afterLeave={() =>
        setShowStoreStock({
          visible: false,
          selectedProduct: null,
          inventory: null,
        })
      }
      unmount={true}
    >
      <Suspense fallback={`Loading...`}>
        <StoreLocator compact={true} />
      </Suspense>
    </DefaultModal>
  );
}

export default StoreLocatorDrawer;
