import clsx from 'clsx';
import { ReactNode } from 'react';

export const DefaultWrapper = ({
  children,
  fullwidth,
  className,
  scrollRef,
}: {
  children: ReactNode;
  fullwidth?: boolean;
  className?: string;
  scrollRef?: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <div
      ref={scrollRef}
      className={clsx(
        { 'xl:container': !fullwidth },
        'globalPadding relative print:max-w-full print:mx-0 print:px-0',
        className
      )}
    >
      {children}
    </div>
  );
};
