import {
  useContext,
  createContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import type {
  Breadcrumb,
  FagmoblerInventory,
  FagmoblerPublicOmniumProduct,
} from '@fagmobler/types';

import useScrollBlock from '@/hooks/scroll-block';
import type {
  TNavigationItem,
  TNavigationBannerItem,
  TNavigationInspirationItem,
} from '@/lib/sanity/queries';

import {
  initialState,
  navigationReducer,
  NavigationActionTypes,
} from './reducer';
import type { NavigationReducerState } from './reducer';
import { OmniumStore } from '@/../../packages/omnium/dist/types';

export type NavigationProviderProps = {
  children: React.ReactNode;
  mainNavigation?: TNavigationItem[];
  menuInspiration?: TNavigationInspirationItem[];
  menuBanners?: TNavigationBannerItem[];
  footer?: TNavigationItem[];
  zipCode?: string;
  breadcrumbs?: Breadcrumb[];
};

export type NavigationContextState = NavigationReducerState & {
  mainNavigation?: TNavigationItem[];
  menuInspiration?: TNavigationInspirationItem[];
  menuBanners?: TNavigationBannerItem[];
  footer?: TNavigationItem[];
  zipCode?: string;
  breadcrumbs?: Breadcrumb[];
  setShowCart: (value?: boolean) => void;
  setZipCode: (value?: string) => void;
  setShowStores: (value?: boolean) => void;
  setShowClubMemberForm: (value?: boolean) => void;
  setShowMemberTerms: (value?: boolean) => void;
  setShowPurchaseTerms: (value?: boolean) => void;
  setShowMainNavigation: (value?: boolean) => void;
  setShowStoreStock: (value?: {
    title?: string;
    visible: boolean;
    selectedProduct?: FagmoblerPublicOmniumProduct | null;
    inventory: FagmoblerInventory | null;
  }) => void;
  setClickAndCollect: (value?: {
    product?: FagmoblerPublicOmniumProduct | null;
    visible?: boolean;
    store?: OmniumStore | null;
    quantity?: number;
    stock?: {
      stock: number;
      display: number;
    };
  }) => void;
};

export const NavigationContext = createContext<
  NavigationContextState | undefined
>(undefined);

export function NavigationProvider({
  children,
  mainNavigation = [],
  menuInspiration = [],
  menuBanners = [],
  breadcrumbs = undefined,
  footer = [],
}: NavigationProviderProps) {
  const [state, dispatch] = useReducer(navigationReducer, initialState);
  const { allowScroll, blockScroll, isBlocked } = useScrollBlock();
  const [zipCode, setZipCode] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      (state.showStores ||
        state.showCart ||
        state.showClubMemberForm ||
        state.showMemberTerms ||
        state.showPurchaseTerms) &&
      !isBlocked
    ) {
      blockScroll();
    }

    if (
      !state.showStores &&
      !state.showCart &&
      !state.showClubMemberForm &&
      !state.showMemberTerms &&
      !state.showPurchaseTerms &&
      isBlocked
    ) {
      allowScroll();
    }
  }, [
    state.showStores,
    state.showCart,
    state.showClubMemberForm,
    state.showMemberTerms,
    state.showPurchaseTerms,
    isBlocked,
    allowScroll,
    blockScroll,
  ]);

  function setShowCart(value?: boolean) {
    dispatch({ type: NavigationActionTypes.TOGGLE_CART, value });
  }

  function setShowStores(value?: boolean) {
    dispatch({ type: NavigationActionTypes.TOGGLE_STORES, value });
  }

  function setShowClubMemberForm(value?: boolean) {
    dispatch({ type: NavigationActionTypes.TOGGLE_MEMBER_FORM, value });
  }

  function setShowMemberTerms(value?: boolean) {
    dispatch({ type: NavigationActionTypes.TOGGLE_MEMBER_TERMS, value });
  }

  function setShowMainNavigation(value?: boolean) {
    dispatch({ type: NavigationActionTypes.TOGGLE_MAIN_NAVIGATION, value });
  }

  function setShowPurchaseTerms(value?: boolean) {
    dispatch({ type: NavigationActionTypes.TOGGLE_PURCHASE_TERMS, value });
  }

  function setShowStoreStock(value?: {
    visible: boolean;
    selectedProduct?: FagmoblerPublicOmniumProduct | null;
    inventory: FagmoblerInventory | null;
  }) {
    dispatch({
      type: NavigationActionTypes.TOGGLE_STORE_STOCK,
      value,
    });
  }

  function setClickAndCollect(value?: {
    visible?: boolean;
    product?: FagmoblerPublicOmniumProduct | null;
    quantity?: number;
    stock?: {
      stock: number;
      display: number;
    };
    store?: OmniumStore | null;
  }) {
    dispatch({
      type: NavigationActionTypes.TOGGLE_CLICK_AND_COLLECT,
      value,
    });
  }

  return (
    <NavigationContext.Provider
      value={{
        ...state,
        setShowCart,
        setShowStores,
        setShowStoreStock,
        setShowClubMemberForm,
        setShowMemberTerms,
        setShowPurchaseTerms,
        setShowMainNavigation,
        setClickAndCollect,
        setZipCode,
        zipCode,
        mainNavigation,
        menuInspiration,
        menuBanners,
        breadcrumbs,
        footer,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigation() {
  const context = useContext(NavigationContext);

  return (
    context || {
      setShowMainNavigation: () => {},
      setShowClubMemberForm: () => {},
      setShowStoreStock: () => {},
      setShowStores: () => {},
      setShowCart: () => {},
      setShowMemberTerms: () => {},
      setShowPurchaseTerms: () => {},
      setClickAndCollect: () => {},
      setZipCode: () => {},
      footer: [],
      breadcrumbs: undefined,
      ...initialState,
    }
  );
}
