import { useEffect, useRef } from 'react';

type BlockWrapperProps = {
  children: React.ReactNode;
  onMount?(): void;
  onView?(): void;
  className?: string;
};

export const BlockWrapper = ({
  children,
  onMount,
  onView,
  className,
}: BlockWrapperProps) => {
  const sentinelRef = useRef(null);

  const isViewed = useRef(false);

  useEffect(() => {
    onMount && onMount();
  }, [onMount]);

  useEffect(() => {
    if (!onView || isViewed.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (
        !isViewed.current &&
        entries.some((entry) => entry.isIntersecting === true)
      ) {
        onView();
        isViewed.current = true;
      }
    });
    if (sentinelRef.current) observer.observe(sentinelRef.current);
    return () => {
      observer.disconnect();
    };
  }, [onView]);

  return (
    <div ref={sentinelRef} className={className}>
      {children}
    </div>
  );
};
