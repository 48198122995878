import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Header from './Header';

import { useNavigation } from '@/contexts/navigation';

import {
  ClubMemberDrawer,
  PurchaseTermsDrawer,
  MemberTermsDrawer,
  StoreLocatorDrawer,
} from '@/components/drawers';
import StoreStockModal from '@/components/store/StoreStockModal';

import Footer from './Footer';
import { BreadcrumbJsonLd, SiteLinksSearchBoxJsonLd } from 'next-seo';
import { Breadcrumbs } from '../breadcrumbs';
import { ClickAndCollectModal } from '../clickAndCollect';
import { DefaultWrapper } from './DefaultWrapper';
import { ZipCode } from '../zip/zipCode';

const Cart = dynamic(() => import('@/components/cart/Cart'), {
  ssr: false,
});

export type DefaultLayoutProps = {
  cataloguePath?: string;
  preview?: boolean;
  children: ReactNode;
  fullwidth?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
};

export const DefaultLayout = ({
  children,
  preview,
  fullwidth = false,
  scrollRef,
}: DefaultLayoutProps) => {
  const router = useRouter();
  const { breadcrumbs } = useNavigation();

  const showBreadcrumbs = !(
    router.pathname == '/' || router.pathname.includes('/utsjekk')
  );

  return (
    <>
      <SiteLinksSearchBoxJsonLd
        url="https://fagmobler.no"
        potentialActions={[
          {
            queryInput: 'search_term_string',
            target: 'https://fagmobler.no/?q',
          },
        ]}
      />
      {preview && (
        <div className="bg-primary-50 text-neutral-0 p-2 flex justify-between text-sm">
          <p>
            Dette er en prøveversjon av siden. Trykk <b>publish</b> i Sanity for
            å publisere denne siden nå.
          </p>

          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a
            href="/api/preview?enable=false"
            className="bg-white text-neutral-80 rounded px-5 whitespace-nowrap flex align-center justify-center"
          >
            Skru av
          </a>
        </div>
      )}

      <Header />

      <div className="pt-[9.5rem] lg:pt-[9rem] print:pt-0">
        {router && !router.pathname.includes('/utsjekk') && <Cart />}

        <DefaultWrapper scrollRef={scrollRef} fullwidth={fullwidth}>
          <>
            <div className="print:hidden flex justify-between flex-col md:flex-row">
              {showBreadcrumbs && breadcrumbs && (
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              )}
              {showBreadcrumbs && breadcrumbs && breadcrumbs.length > 0 && (
                <BreadcrumbJsonLd
                  itemListElements={breadcrumbs?.map((crumb, i) => {
                    return {
                      position: i + 1,
                      name: crumb.name,
                      item: `https://fagmobler.no/${crumb.path}`,
                    };
                  })}
                />
              )}
              <ZipCode className="-order-1 -mt-6 mb-2 md:order-1 md:mt-0 md:mb-2" />
            </div>

            {children}
          </>
        </DefaultWrapper>

        <div className="print:hidden">
          <StoreLocatorDrawer />
          <ClubMemberDrawer />
          <PurchaseTermsDrawer />
          <MemberTermsDrawer />
          <StoreStockModal />
          <ClickAndCollectModal />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DefaultLayout;
