import clsx from 'clsx';

import { Alert, Badge, Button, Sticker, StockIndicators } from '@fagmobler/ui';

import { StoreAddress } from './StoreAddress';
// import { StoreOpeningHours } from './StoreOpeningHours';
// import { ShowStoreInfoButton } from './ShowStoreInfoButton';

import type { StockDetails } from './useStoresWithStock';
import { getStoreIsWebshop, OmniumStore } from '@fagmobler/omnium';
import StoreHasCartInStockIndicator from './StoreHasCartInStockIndicator';

export type StoreOptionProps = {
  stock?: StockDetails | null;
  storeHasCartInStock?: boolean;
  store: OmniumStore & { distance?: number };
  isSelected: boolean;
  distance?: number;
  onSelectStore: () => void;
};

/**
 * StoreOption component
 *
 * @returns {JSX}
 */
export function StoreOption({
  stock,
  store,
  isSelected,
  storeHasCartInStock,
  onSelectStore,
  distance,
}: StoreOptionProps) {
  const storeIsWebshop = getStoreIsWebshop(store);
  const distanceToStore = store?.distance || distance;
  return (
    <div
      className={clsx(
        'relative',
        'w-full',
        'mt-2',
        'p-4',
        'bg-white',
        'shadow-lg',
        'rounded-md',
        'flex',
        'items-end',
        'text-left'
      )}
    >
      <div className="w-full">
        {isSelected && (
          <div className={clsx('mb-2')}>
            <Sticker size="sm" className="uppercase">
              Min butikk
            </Sticker>
          </div>
        )}
        <StoreAddress store={store} />
        {distanceToStore ? (
          <span className="text-neutral-60 cap-xxs block mt-2">
            {distanceToStore.toFixed(1)} km
          </span>
        ) : null}
        {typeof stock !== 'undefined' && (
          <div className={clsx('mt-2', 'mb-3')}>
            <StockIndicators stock={stock} />
          </div>
        )}
        {typeof stock === 'undefined' &&
          typeof storeHasCartInStock !== 'undefined' && (
            <StoreHasCartInStockIndicator
              storeHasCartInStock={storeHasCartInStock}
            />
          )}
        {!storeIsWebshop && (
          <Alert inline type="warning">
            {store.name} tilbyr ikke kjøp på nett
          </Alert>
        )}
      </div>
      {!isSelected && (
        <Button onClick={onSelectStore} disabled={!storeIsWebshop}>
          Velg
        </Button>
      )}
    </div>
  );
}

export default StoreOption;
