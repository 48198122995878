import { useMemo } from 'react';

import type { OmniumStore } from '@fagmobler/omnium';
import type { FagmoblerInventory } from '@fagmobler/types';
export type StockDetails = FagmoblerInventory['sku']['storeId'];

export type StoreStock = {
  [sku: string]: StockDetails;
};

export interface OmniumStoreWithStock extends OmniumStore {
  stock: StoreStock;
}

export function useStoresWithStock(
  stores: OmniumStore[],
  inventory: FagmoblerInventory
) {
  return useMemo(() => {
    return (stores || []).map((store) => ({
      ...store,
      stock: store.id ? inventory[store.id] || {} : {},
    }));
  }, [stores, inventory]);
}

export default useStoresWithStock;
