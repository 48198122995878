import { TNavigationItem } from '@/lib/sanity/queries';
import { DrawerItem } from './';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';
import Link from 'next/link';
import { DrawerModal } from '@fagmobler/ui';
import { useRef, useState } from 'react';
import { useNavigation } from '@/contexts/navigation';

type NavigationDrawerProps = {
  selectedMainItem: TNavigationItem | null;
  onClose(): void;
};

export function NavigationDrawer({
  selectedMainItem,
  onClose,
}: NavigationDrawerProps) {
  const {
    mainNavigation = [],
    showMainNavigation,
    setShowMainNavigation,
  } = useNavigation();
  const [hasChildModal, setHasChildModal] = useState(!!selectedMainItem);
  let initialFocusRef = useRef<any>(null);

  const primaryNav = mainNavigation.filter(
    (item) => item.navType === 'primary'
  );

  const secondaryNav = mainNavigation.filter(
    (item) => item.navType !== 'tertiary' && item.navType !== 'primary'
  );

  const extraNav = mainNavigation.filter((item) => item.navType === undefined);

  const handleClose = () => {
    // setHasChildModal(false);
    setShowMainNavigation(false);
    onClose();
  };

  const handleItemClose = () => {
    setHasChildModal(false);
    onClose();
  };

  return (
    <DrawerModal
      isOpen={showMainNavigation}
      onClose={handleClose}
      position="left"
      unmount={false}
      hideHeader={hasChildModal}
      className="max-w-sm"
    >
      <ul className="border-t border-neutral-40 p-6 text-neutral-80 pt-8">
        {primaryNav.map((item: TNavigationItem, i) => (
          <DrawerItem
            item={item}
            key={`primary_${item._key}`}
            mainItem
            closeDrawer={handleClose}
            onOpen={() => setHasChildModal(true)}
            onClose={handleItemClose}
            onMenuClose={handleClose}
            defaultOpen={selectedMainItem === item}
            ref={i === 0 ? initialFocusRef : undefined}
          />
        ))}
      </ul>
      <ul className={`border-t border-neutral-40 p-6 mt-4`}>
        {secondaryNav.map((item: TNavigationItem) => {
          const href =
            item.internalLink &&
            getPathFromInternalLink(item.internalLink, item);
          if (!href) return null;
          return (
            <li key={`secondaryItem_${item._key}`}>
              <Link
                href={href}
                className="block py-2 px-4 text-neutral-80 hover:underline underline-offset-4"
                tabIndex={!!hasChildModal ? -1 : undefined}
                onClick={handleClose}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className={`pt-2 px-6 text-sm`}>
        {extraNav.map((item: TNavigationItem) => {
          const href =
            item.internalLink &&
            getPathFromInternalLink(item.internalLink, item);
          if (!href) return null;
          return (
            <li key={`extraNav_${item._key}`}>
              <Link
                href={href}
                className="block text-neutral-80 focus-visible:ring-2 ring-focus hover:underline underline-offset-4"
                tabIndex={!!hasChildModal ? -1 : undefined}
                onClick={handleClose}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </DrawerModal>
  );
}

export default NavigationDrawer;
