import { Suspense } from 'react';
import dynamic from 'next/dynamic';
import { DrawerModal } from '@fagmobler/ui';

import { useNavigation } from '@/contexts/navigation';

const MemberTerms = dynamic(() => import('./MemberTerms'));

export function MemberTermsDrawer() {
  const { showMemberTerms, setShowMemberTerms } = useNavigation();

  return (
    <DrawerModal
      isOpen={showMemberTerms}
      onClose={() => setShowMemberTerms(false)}
      title="Personsvernerklærling kundeklubb"
      unmount={true}
    >
      <Suspense fallback={`Loading...`}>
        <MemberTerms />
      </Suspense>
    </DrawerModal>
  );
}

export default MemberTermsDrawer;
