// src/config.ts
var COOKIE_OMNIUM_CART_ID = "fm.cid";
var COOKIE_OMNIUM_STORE_ID = "fm.sid";
var COOKIE_LEGACY_STORE_ID = "bid";
var COOKIE_OMNIUM_ORDER_ID = "fm.oid";
var BASE_URL = process.env.OMNIUM_API_URL || "";

// src/client/client.ts
async function api(pathname, {
  method = "GET",
  headers: h = {},
  body,
  token,
  searchParams = {}
} = {}) {
  var _a, _b;
  let url = `${BASE_URL}/api/${pathname}`;
  if (Object.entries(searchParams).length > 0) {
    const params = new URLSearchParams(searchParams);
    url = `${url}?${params.toString()}`;
  }
  const headers = {
    ...h || {},
    ["Accept"]: "application/json"
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const options = {
    method
  };
  if (body) {
    if (typeof body === "string") {
      options.body = body;
    } else {
      options.body = JSON.stringify(body);
    }
    headers["Content-Type"] = "application/json";
  }
  options.headers = headers;
  const response = await fetch(url, options);
  if (!response.ok) {
    console.error("ERROR!");
    console.log("response status -> ", response.status);
    console.log("response statusText -> ", response.statusText);
    console.log("url -> ", url.toString());
    console.log("options -> ", JSON.stringify(options));
    console.log("body -> ", JSON.stringify(body));
    if ((_a = response.headers.get("Content-Type")) == null ? void 0 : _a.includes("application/json")) {
      console.log("response -> ", await response.json());
    }
    throw new Error(`${response.status} ${response.statusText}`);
  }
  if ((_b = response.headers.get("Content-Type")) == null ? void 0 : _b.includes("application/json")) {
    return response.json();
  }
  return response.text();
}
var ApiClient = class {
  token;
  constructor(token) {
    if (!token) {
      throw new Error("Missing token");
    }
    this.token = token;
  }
  get(pathname, opts = {}) {
    return api(pathname, { ...opts, method: "GET", token: this.token });
  }
  post(pathname, opts = {}) {
    return api(pathname, { ...opts, method: "POST", token: this.token });
  }
  put(pathname, opts = {}) {
    return api(pathname, { ...opts, method: "PUT", token: this.token });
  }
  delete(pathname, opts = {}) {
    return api(pathname, { ...opts, method: "DELETE", token: this.token });
  }
  patch(pathname, opts = {}) {
    return api(pathname, { ...opts, method: "PATCH", token: this.token });
  }
};

// src/client/helpers.ts
var getStoreIsWebshop = (store) => {
  var _a;
  const DISABLE_ONLINE_CART = (_a = store == null ? void 0 : store.properties) == null ? void 0 : _a.find(
    (p) => p.key === "DISABLE_ONLINE_CART" && p.value === "true"
  );
  return typeof DISABLE_ONLINE_CART === "undefined" ? true : false;
};

// src/api/Cart/Cart.ts
var Cart = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async create() {
    return this.client.post("Cart/CreateCart");
  }
  async getOrCreate(cartId) {
    if (!cartId) {
      return this.create();
    }
    return this.client.get(`Cart/${cartId}`);
  }
  async get(cartId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.get(`Cart/${cartId}`);
  }
  async update(cart) {
    return this.client.put(`Cart`, { body: cart });
  }
  async delete(cartId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.delete(`Cart/${cartId}`);
  }
  async recalculatePrices(cartId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.put(`Cart/${cartId}/RecalculatePrices`);
  }
  async addCustomer(cartId, customerId) {
    if (!customerId) {
      throw new Error("missing customer id");
    }
    return this.client.put(
      `Cart/${cartId}/AddCustomer/${customerId}`,
      {
        searchParams: {
          enrichCart: "true"
        }
      }
    );
  }
  async addShipments(cartId, shippingOption) {
    if (!cartId || !shippingOption) {
      throw new Error("missing customer id");
    }
    return this.client.put(`Cart/${cartId}/AddShipments`, {
      body: [shippingOption].flat()
    });
  }
  async addItem({
    skuId,
    quantity = 1,
    ...rest
  }) {
    if (!skuId) {
      throw new Error("missing sku");
    }
    return this.client.post(`Cart/AddItemToCart`, {
      searchParams: {
        skuId,
        quantity,
        ...Object.entries(rest).reduce((acc, [key, value]) => {
          if (key && typeof value !== "undefined" && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {})
      }
    });
  }
  async updateOrderLine(cartId, {
    code,
    placedPrice,
    placedPriceExclTax,
    extendedPrice,
    extendedPriceExclTax,
    discountedPrice,
    discountedPriceExclTax
  }) {
    if (!cartId || !code) {
      throw new Error("missing cartId or sku");
    }
    return this.client.put(`Cart/${cartId}/OrderLines`, {
      body: {
        code,
        placedPrice,
        placedPriceExclTax,
        extendedPrice,
        extendedPriceExclTax,
        discountedPrice,
        discountedPriceExclTax
      }
    });
  }
  async patch(cartId, data) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.patch(`Cart/${cartId}/PatchCart`, {
      body: data
    });
  }
  async updateQuantity(cartId, lineItemId, quantity = 1) {
    if (!lineItemId) {
      throw new Error("missing code or sku");
    }
    if (!cartId) {
      throw new Error("missing cart id");
    }
    return this.client.post(
      `Cart/${cartId}/OrderLines/SetQuantity/OrderLineId/${lineItemId}/${quantity}`
    );
  }
  async removeItem(cartId, lineItemId) {
    if (!lineItemId) {
      throw new Error("missing code or sku");
    }
    if (!cartId) {
      throw new Error("missing cart id");
    }
    return this.client.delete(
      `Cart/${cartId}/OrderLines/${lineItemId}`
    );
  }
  async setStoreId(cartId, storeId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    if (!storeId) {
      throw new Error("Missing store id");
    }
    return this.client.put(`Cart/${cartId}/AddStore/${storeId}`);
  }
  async getShippingOptions(cartId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.get(
      `Cart/${cartId}/GetShippingOptions`
    );
  }
  async getPaymentOptions(cartId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.get(
      `Cart/${cartId}/GetPaymentOptions`
    );
  }
  async addPayments(cartId, payments) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.put(`Cart/${cartId}/AddPayments`, {
      body: payments
    });
  }
  async createOrderFromCart(cartId, orderType = "Online") {
    if (!cartId) {
      throw new Error("Missing order id");
    }
    return this.client.post(
      `Cart/CreateOrderFromCart/${cartId}`,
      orderType ? { searchParams: { orderType } } : {}
    );
  }
};

// src/api/Inventory/Inventory.ts
var Inventory = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async getOne(productId) {
    if (!productId) {
      throw new Error("Missing product id");
    }
    return this.client.get(
      `Inventory/${productId}`
    );
  }
};

// src/api/Notifications/Notifications.ts
var Notification = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async sendEmail(body) {
    return this.client.post(`Notifications/SendEmail`, {
      body
    });
  }
  async sendSms(body) {
    return this.client.post(`Notifications/SendSms`, {
      body
    });
  }
};

// src/api/Order/Order.ts
var Order = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async get(orderId) {
    if (!orderId) {
      throw new Error("Missing order id");
    }
    return this.client.get(`Orders/${orderId}`);
  }
  async getByCartId(cartId) {
    if (!cartId) {
      throw new Error("Missing cart id");
    }
    return this.client.get(`Orders/carts/${cartId}`);
  }
  async create(order) {
    return this.client.post("Orders", { body: order });
  }
};

// src/api/Settings/Settings.ts
var Settings = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async getPaymentSettings(storeId) {
    return this.client.get(
      `settings/GetPaymentSettings`,
      {
        searchParams: storeId ? { storeId } : void 0
      }
    );
  }
};

// src/api/PrivateCustomer/PrivateCustomer.ts
var PrivateCustomer = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async create(customer) {
    return this.client.post("PrivateCustomers", {
      body: customer
    });
  }
  async addToMemberClub(customerId) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.post(
      `PrivateCustomers/${customerId}/CustomerClubMember/Add`
    );
  }
  async addToMemberClubWithConsents(customerId, consentList) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.post(
      `PrivateCustomers/${customerId}/CustomerClubMember/AddWithConsentList`,
      {
        body: consentList
      }
    );
  }
  async get(customerId) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.get(
      `PrivateCustomers/${customerId}`
    );
  }
  async getClubMember(customerId) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.get(
      `PrivateCustomers/${customerId}/CustomerClubMember`
    );
  }
  async update(customer) {
    if (!customer.customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.post("PrivateCustomers/Update", {
      body: customer
    });
  }
  async updateClubMemberConsents(customerId, consentList) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.post(
      `PrivateCustomers/${customerId}/CustomerClubMember/UpdateConsentList`,
      {
        body: consentList
      }
    );
  }
  async removeFromMemberClub(customerId) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.delete(
      `PrivateCustomers/${customerId}/CustomerClubMember/Remove`
    );
  }
  async anonymize(customerId) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.delete(`PrivateCustomers/${customerId}/Anonymize`);
  }
  async delete(customerId) {
    if (!customerId) {
      throw new Error("Missing customer id");
    }
    return this.client.delete(`PrivateCustomers/${customerId}/Remove`);
  }
};

// src/api/Product/Product.ts
var Product = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async getAll() {
    return this.client.get(`Products`);
  }
  async getOne(productId) {
    if (!productId) {
      throw new Error("Missing product id");
    }
    return this.client.get(`Products/${productId}`);
  }
  async create(product) {
    return this.client.post("Products", { body: product });
  }
};

// src/api/ProductCategories/ProductCategories.ts
var ProductCategories = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async getAll() {
    return this.client.get("ProductCategories");
  }
  async getSubCategories(parentId) {
    return this.client.get(
      `ProductCategories/${parentId}/SubCategories`
    );
  }
};

// src/api/Store/Store.ts
var Store = class {
  client;
  constructor(token) {
    this.client = new ApiClient(token);
  }
  async getAll() {
    return this.client.get(`Stores`);
  }
  async getOne(storeId) {
    if (!storeId) {
      throw new Error("Missing store id");
    }
    return this.client.get(`Stores/${storeId}`);
  }
  async create(store) {
    return this.client.post("Stores", { body: store });
  }
};

// src/api/Token/Token.ts
async function createToken(clientId, clientSecret) {
  console.log("Creating new omnium token");
  return api("Token", {
    method: "POST",
    searchParams: { clientId, clientSecret }
  });
}

// src/mocks/order.ts
var createMockOrder = (options) => ({
  id: null,
  orderNumber: "001",
  customerId: "001",
  customerNumber: "",
  billingCurrency: "NOK",
  customerName: options.order.get.customer.firstName,
  customerPhone: "",
  customerEmail: options.order.get.customer.email,
  customerType: null,
  marketId: "NOR",
  billingAddress: {
    name: null,
    firstName: "",
    lastName: "",
    organization: null,
    line1: "",
    line2: null,
    city: "",
    state: null,
    countryCode: "NO",
    countryName: null,
    postalCode: "",
    regionCode: null,
    regionName: null,
    daytimePhoneNumber: "",
    eveningPhoneNumber: null,
    email: options.order.get.customer.email
  },
  orderForm: {
    shipments: [
      {
        discounts: [
          {
            discountType: "Shipping",
            skuIds: [],
            discountId: null,
            discountAmount: 0,
            discountCode: "FreeShipping",
            discountName: "Gratis frakt over 1000 kr",
            discountValue: 100,
            discountSource: null,
            rewardType: "Percentage"
          }
        ],
        shipmentId: "",
        shippingMethodId: null,
        shippingMethodName: "",
        shippingLabel: null,
        shippingTax: 0,
        shipmentTrackingNumber: null,
        shipmentTrackingLink: null,
        shippingDiscountAmount: 0,
        shippingSubTotal: 0,
        shippingTotal: 0,
        shippingSubTotalExclTax: 0,
        status: "AwaitingInventory",
        prevStatus: null,
        subTotal: 0,
        total: 0,
        taxTotal: 0,
        warehouseCode: "",
        pickUpWarehouseCode: null,
        lineItems: [
          {
            lineItemId: "",
            code: "",
            displayName: "",
            alternativeProductName: null,
            placedPrice: 0,
            placedPriceExclTax: 0,
            extendedPrice: 0,
            extendedPriceExclTax: 0,
            discountedPrice: 0,
            discountedPriceExclTax: 0,
            suggestedRetailPrice: 0,
            suggestedRetailPriceExclTax: 0,
            orderDiscountAmount: 0,
            comment: null,
            quantity: 0,
            unit: null,
            returnQuantity: 0,
            returnReason: null,
            canceledQuantity: 0,
            deliveredQuantity: 0,
            isGift: false,
            isReadOnly: true,
            cost: 28900,
            costTotal: 28900,
            discounted: 0,
            discountedExclTax: 31120,
            taxTotal: 6998.19,
            taxRate: 25,
            campaignId: null,
            properties: [],
            size: "",
            color: "",
            brand: null,
            supplierSkuId: null,
            supplierName: null,
            productId: "",
            imageUrl: null,
            orderLineDiscounts: [],
            orderLineBonusPoints: null,
            expectedDeliveryDate: null,
            serialNumber: null,
            packageSkuId: null,
            packageLineItemId: null,
            webSiteProductUrl: null,
            updateStock: false,
            replacedQuantity: null,
            replacedDate: null,
            replacementType: null,
            replacementReason: null
          }
        ],
        properties: [],
        address: {
          name: null,
          firstName: "",
          lastName: "",
          organization: null,
          line1: "",
          line2: null,
          city: "Oslo",
          state: null,
          countryCode: "NO",
          countryName: null,
          postalCode: "",
          regionCode: null,
          regionName: null,
          daytimePhoneNumber: "",
          eveningPhoneNumber: "",
          email: ""
        },
        transit: false,
        comment: null,
        returnTrackingNumber: null,
        returnTrackingLink: null,
        returnLabelLink: null,
        servicePointId: null,
        pickUpPointInformation: null,
        expectedDeliveryDate: "",
        packages: [],
        expectedShipmentDate: null,
        shippingMethodProductId: null,
        externalIds: null
      }
    ],
    payments: [
      {
        amount: 0,
        authorizationCode: null,
        customerName: null,
        implementationClass: null,
        id: null,
        paymentId: 0,
        paymentMethodId: "",
        paymentMethodName: "",
        paymentType: null,
        status: "",
        transactionId: "",
        transactionType: "",
        validationCode: null,
        properties: [],
        paidDate: null,
        created: null,
        isManuallyAdded: false,
        comment: null,
        internalReference: null,
        reason: null
      }
    ],
    shippingDiscountTotal: 0,
    shippingSubTotal: 0,
    shippingSubTotalExclTax: 0,
    cartName: "",
    cartId: "",
    couponCodes: [],
    shippingTotal: 0,
    handlingTotal: 0,
    orderLevelTax: 0,
    taxTotal: 4,
    discountAmount: 0,
    subTotal: 36519.2,
    subTotalExclTax: 0,
    total: 36519.2,
    totalExclTax: 29215.36,
    authorizedPaymentTotal: 0,
    capturedPaymentTotal: 0,
    properties: [],
    charges: null,
    fullRefund: false
  },
  orderNotes: null,
  shippingDiscountTotal: 0,
  shippingSubTotal: 0,
  status: "New",
  subTotal: 0,
  subTotalExclTax: 0,
  taxTotal: 0,
  total: 0,
  totalExclTax: 0,
  discountTotalIncVat: 0,
  remainingPayment: 0,
  salesPersonId: "eivind@omnium.no",
  salesPersonName: null,
  salesChannel: null,
  modified: "2020-06-03T08:55:31",
  modifiedBy: null,
  created: "2020-06-03T08:55:31",
  received: null,
  customerPickupDeadline: null,
  storePickDate: null,
  requestedDeliveryDate: null,
  properties: [],
  paymentType: null,
  orderType: "Online",
  storeId: "WebShop",
  returnOrderForms: [],
  orderOrigin: null,
  lineItemsCost: 0,
  netTotal: 0,
  netTotalExclTax: 0,
  customerReference: null,
  customerContact: null,
  customerRequisition: null,
  externalIds: [],
  isReadOnly: false,
  errors: null,
  relatedOrders: null,
  session: null,
  assets: null,
  replacementOrderIds: null
});

// src/main.ts
var Omnium = class {
  cart;
  inventory;
  notification;
  order;
  privateCustomer;
  productCategories;
  product;
  store;
  settings;
  constructor(token) {
    this.cart = new Cart(token);
    this.inventory = new Inventory(token);
    this.notification = new Notification(token);
    this.order = new Order(token);
    this.privateCustomer = new PrivateCustomer(token);
    this.product = new Product(token);
    this.productCategories = new ProductCategories(token);
    this.store = new Store(token);
    this.settings = new Settings(token);
  }
  static async getToken({ clientId, clientSecret }) {
    if (!clientId || !clientSecret) {
      throw new Error("Missing Omnium credentials");
    }
    const token = await createToken(clientId, clientSecret);
    return token;
  }
};
export {
  ApiClient,
  BASE_URL,
  COOKIE_LEGACY_STORE_ID,
  COOKIE_OMNIUM_CART_ID,
  COOKIE_OMNIUM_ORDER_ID,
  COOKIE_OMNIUM_STORE_ID,
  Cart,
  Inventory,
  Notification,
  Omnium,
  Order,
  PrivateCustomer,
  Product,
  ProductCategories,
  Settings,
  Store,
  api,
  createMockOrder,
  createToken,
  getStoreIsWebshop
};
