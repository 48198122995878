export const regions = {
  '03': {
    iso: '03',
    name: 'Oslo',
  },
  '11': {
    iso: '11',
    name: 'Rogaland',
  },
  '15': {
    iso: '15',
    name: 'Møre og Romsdal',
  },
  '18': {
    iso: '18',
    name: 'Nordland',
  },
  '31': {
    iso: '31',
    name: 'Østfold',
  },
  '32': {
    iso: '32',
    name: 'Akershus',
  },
  '33': {
    iso: '33',
    name: 'Buskerud',
  },
  '34': {
    iso: '34',
    name: 'Innlandet',
  },
  '39': {
    iso: '39',
    name: 'Vestfold',
  },
  '40': {
    iso: '40',
    name: 'Telemark',
  },
  '42': {
    iso: '42',
    name: 'Agder',
  },
  '46': {
    iso: '46',
    name: 'Vestland',
  },
  '50': {
    iso: '50',
    name: 'Trøndelag',
  },
  '55': {
    iso: '55',
    name: 'Troms',
  },
  '56': {
    iso: '56',
    name: 'Finnmark',
  },
};

export const defaultRegion = regions['03'];

export default regions;
