import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Button,
  Alert,
  formatPrice,
  DrawerModal,
  Loading,
} from '@fagmobler/ui';

import {
  convertCartDataIntoEcommerceObject,
  trackEvent,
} from '@/lib/analytics';

import { useStores, useStoresWithStock } from '@/components/store';
import { useNavigation } from '@/contexts/navigation';

import { useCart } from './useCart';
import { useDeleteCart } from './useDeleteCart';
import CartStore from './CartStore';
import CartItem from './CartItem';
import clsx from 'clsx';
import { getInventoryData, useInventory } from '../inventory/useInventory';

export function Cart() {
  const router = useRouter();
  const { showCart, setShowCart } = useNavigation();
  const { cart: { data: cart = {}, isFetching } = {} } = useCart();
  const {
    stores: { data: rawStores = [] },
    current: { data: { store: rawStore = {} } = {} },
  } = useStores();
  const deleteCart = useDeleteCart();

  const inventoryResult = useInventory({
    products: cart?.products ?? [],
  });
  const inventory = getInventoryData(inventoryResult);

  const stores = useStoresWithStock(rawStores, inventory);
  const [store] = useStoresWithStock(rawStore ? [rawStore] : [], inventory);

  useEffect(() => {
    if (showCart && cart?.id) {
      trackEvent(
        'view_cart',
        convertCartDataIntoEcommerceObject({ cart }),
        store
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCart]);

  useEffect(() => {
    return () => setShowCart(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id, orderForm: { lineItems = [], taxTotal, subTotal } = {} } = cart;

  const isVividworksCart = cart.salesChannel === 'API';

  const itemCount =
    (lineItems || []).reduce(
      (acc: number, item) => acc + (item.quantity || 0),
      0
    ) ?? 0;

  const totalSaved = isVividworksCart
    ? 0
    : (lineItems || []).reduce((acc, item) => acc + (item.discounted ?? 0), 0);

  const memberDiscountSum =
    cart.products?.reduce((acc, p) => {
      if (p.price.promotionId === 'Member') {
        return (
          acc +
          (p.price?.discountAmount || 0) *
            ((lineItems || []).find((item) => item.code === p.skuId)
              ?.quantity ?? 1)
        );
      }
      return acc;
    }, 0) ?? 0;

  const cartTotal = subTotal ?? 0;

  // Merge multiple lines from modulbygger based on packageLineItemId
  const lineItemsByPackage = lineItems?.reduce((acc, item) => {
    if (item.isPackage) {
      const hasItemsInAcc = acc.find(
        (i) => i.packageLineItemId === item.packageLineItemId
      );
      if (hasItemsInAcc) return acc;
      const items = lineItems.filter(
        (i) => i.packageLineItemId === item.packageLineItemId
      );
      const bygger =
        items[0].properties?.find((p) => p.key === 'bygger')?.value ||
        'modulbygger';
      const serie = item.properties?.find((p) => p.key === 'serie')?.value;
      const orgDescription = item.properties?.find(
        (p) => p.key === 'description'
      );
      const description = [item.comment, orgDescription]
        .filter(Boolean)
        .join(' ');

      return [
        ...acc,
        {
          ...item,
          displayName: `${serie || 'Produkt'} fra ${bygger.toLowerCase()}`,
          description,
          // placedPrice: items.reduce((a, item) => a + (item.placedPrice ?? 0), 0),
          // placedPriceExclTax: items.reduce((a, item) => a + (item.placedPriceExclTax ?? 0), 0),
          discounted: items.reduce((a, item) => a + (item.discounted ?? 0), 0),
          discountedExclTax: items.reduce(
            (a, item) => a + (item.discountedExclTax ?? 0),
            0
          ),
          discountedPrice: items.reduce(
            (a, item) => a + (item.discountedPrice ?? 0),
            0
          ),
          discountedPriceExclTax: items.reduce(
            (a, item) => a + (item.discountedPriceExclTax ?? 0),
            0
          ),
          extendedPrice: items.reduce(
            (a, item) => a + (item.extendedPrice ?? 0),
            0
          ),
          extendedPriceExclTax: items.reduce(
            (a, item) => a + (item.extendedPriceExclTax ?? 0),
            0
          ),
        },
      ];
    }

    return [...acc, item];
  }, [] as typeof lineItems);

  return (
    <DrawerModal
      isOpen={showCart}
      onClose={() => setShowCart(false)}
      title="Din handlekurv"
    >
      {!cart.id || !cart.orderForm?.lineItems?.length ? (
        <section className="flex flex-col justify-center items-center h-full text-center">
          <div className="p-4 md:p-6">
            <p>Din handlekurv er tom.</p>
          </div>
          <Button
            onClick={() => setShowCart(false)}
            className="text-text-primary underline"
          >
            Ta en tur i butikken og fyll den opp
          </Button>
        </section>
      ) : (
        <section className="flex flex-col h-full">
          <div
            className={clsx('border-b border-b-neutral-40 w-full p-4 md:p-6', {
              'bg-tetiery-40': !store,
              'shadow-lg': store,
            })}
          >
            <CartStore stores={stores} store={store} cart={cart} />
          </div>

          <ol className="p-4 md:p-6">
            {lineItemsByPackage?.map((item) => (
              <CartItem
                item={item}
                product={cart.products?.find((p) => p.skuId === item.code)}
                key={item.lineItemId}
                store={store}
                inventory={inventory}
              />
            ))}
          </ol>

          <footer className="p-6 mt-auto">
            <header>
              <h4 className="hd-md mb-4">Sammendrag</h4>
            </header>
            <table className="body-xs w-full">
              <tbody>
                <tr>
                  <th className="text-left font-normal">
                    Sum {itemCount} varer
                  </th>
                  <td className="text-right">{formatPrice(cartTotal)}</td>
                </tr>
              </tbody>
            </table>
            <span className="block my-3 border-t-1 border-neutral-80 w-full" />
            <table className="w-full mb-2">
              <tbody>
                <tr>
                  <th className="text-left font-normal cap-base">
                    Total ink. moms og eks. frakt
                  </th>
                  <td className="text-right hd-base">
                    {formatPrice(cartTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
            {totalSaved > 0 &&
              lineItemsByPackage?.find((item) =>
                item.orderLineDiscounts?.find(
                  (discount) => discount.discountId === 'Member'
                )
              ) && (
                <p className="body-xs text-primary-50 mb-4">
                  Bli medlem i neste steg og spar{' '}
                  {formatPrice(memberDiscountSum)}
                </p>
              )}
            <div className="mb-2">
              {!store?.id && (
                <Alert type="warning" inline>
                  Du må velge butikk for å gå videre
                </Alert>
              )}
              <Button
                className="w-full text-center flex justify-center"
                size="lg"
                disabled={!store?.id}
                onClick={() => router.push('/utsjekk')}
              >
                Gå til utsjekk
              </Button>
            </div>
            <Button
              onClick={async () => {
                trackEvent(
                  'remove_from_cart',
                  convertCartDataIntoEcommerceObject({ cart }),
                  store
                );
                await deleteCart.mutateAsync();
              }}
              className="w-full text-center flex justify-center"
              variant="ghost"
            >
              Tøm
            </Button>
          </footer>
        </section>
      )}
      {isFetching && <Loading cover showBackground />}
    </DrawerModal>
  );
}

export default Cart;
