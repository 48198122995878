import { OmniumStore } from '@fagmobler/omnium';
import slugify from 'slugify';
import noRegions from '../no-regions';

export const getStorePath = (store: OmniumStore) => {
  if (!store.name) return null;
  const storeRegion = store?.externalIdsList?.find(
    ({ providerName }) => providerName === 'Fylke'
  );
  const region = noRegions[storeRegion?.id as keyof typeof noRegions];
  const regionSlug = slugify(region.name, { lower: true });
  const storeSlug = slugify(store.name, { lower: true });

  return `/min-butikk/${regionSlug}/${storeSlug}`;
};
