/* eslint-disable react/display-name */
/* eslint-disable @next/next/no-img-element */
import { ForwardedRef, forwardRef, Fragment, useState } from 'react';
import Link from 'next/link';
import { Disclosure, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { TNavigationItem } from '@/lib/sanity/queries';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';
import NextImage from 'next/image';
import clsx from 'clsx';
import { IconButton } from '@fagmobler/ui';

interface DrawerItemProps {
  item: TNavigationItem;
  mainItem?: boolean;
  className?: string;
  defaultOpen?: boolean;
  navLayout?: TNavigationItem['navLayout'];
  ref?: React.MutableRefObject<any>;

  onClose?(): void;

  onMenuClose?(): void;

  closeDrawer(): void;

  onOpen(): void;
}

const MainButton = forwardRef(
  (
    {
      hasChildren,
      navLayout,
      mainItem,
      onOpen,
      item,
      className,
      closeDrawer,
    }: {
      hasChildren?: boolean;
      navLayout?: TNavigationItem['navLayout'];
      mainItem: DrawerItemProps['mainItem'];
      onOpen: DrawerItemProps['onOpen'];
      item: DrawerItemProps['item'];
      className: DrawerItemProps['className'];
      closeDrawer?: DrawerItemProps['closeDrawer'];
    },
    ref: ForwardedRef<any>
  ) => {
    const hasImage = item.image && item.image.croppedImageUrl;

    const classNames = clsx(
      'flex cursor-pointer w-full outline-offset-8 hover:underline underline-offset-4',
      {
        'text-lg': mainItem,
        'text-base': !mainItem,
        'px-10': !mainItem && !hasImage,
        'p-4 items-center': !hasImage,
        'flex-col gap-2': hasImage && navLayout !== 'list',
        'flex-row gap-4 items-center py-2': navLayout === 'list',
      },
      className
    );

    const href =
      item.internalLink && getPathFromInternalLink(item.internalLink, item);

    const image = item.image && item.image.croppedImageUrl && (
      <figure
        className={clsx('relative overflow-hidden', {
          'w-full h-[79px]': navLayout === 'grid',
          'w-[70px] h-[48px]': navLayout === 'list',
        })}
      >
        <NextImage
          src={item.image.croppedImageUrl}
          alt={item.image.alt || item.title}
          style={{ objectFit: item.objectFit }}
          className="w-auto h-auto"
          fill
        />
      </figure>
    );

    if (hasChildren) {
      return (
        <Disclosure.Button
          ref={ref}
          className={classNames}
          onKeyDown={(event: any) => event.code == 'Enter' && onOpen()}
          onClick={onOpen}
        >
          {image}
          <span
            className={clsx('first-letter:block', {
              'font-bold': !hasImage,
            })}
          >
            {item.title}
          </span>
          {!hasImage && <ArrowRightIcon className="h-5 w-5 mr-2 ml-auto" />}
        </Disclosure.Button>
      );
    }

    if (href) {
      return (
        <Link legacyBehavior href={href} ref={ref}>
          <a
            className={classNames}
            onClick={closeDrawer ? closeDrawer : () => {}}
          >
            {image}
            <span className="first-letter:block font-bold">{item.title}</span>
          </a>
        </Link>
      );
    }

    return null;
  }
);

export const DrawerItem = forwardRef(
  (
    {
      item,
      mainItem,
      className = '',
      defaultOpen,
      navLayout = 'list',
      onClose,
      onMenuClose,
      closeDrawer,
      onOpen,
    }: DrawerItemProps,
    ref
  ) => {
    const handleCloseDrawer = () => {
      if (closeDrawer) return closeDrawer();
      console.error('Could not close drawer');
    };

    const [hasChildDrawer, setHasChildDrawer] = useState(false);
    const hasChildren = item.children && item.children.length > 0;

    return (
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <MainButton
              hasChildren={hasChildren}
              className={className}
              item={item}
              navLayout={navLayout}
              mainItem={mainItem}
              onOpen={onOpen}
              closeDrawer={closeDrawer}
            />
            <Transition
              as={Fragment}
              show={open}
              enter="transform translate-x-full transition duration-120 ease-out"
              enterFrom="transform translate-x-full"
              enterTo="transform translate-x-0"
              leave="transform translate-x-0 transition duration-100 ease-in"
              leaveFrom="transform translate-x-0"
              leaveTo="transform translate-x-full"
              unmount={false}
            >
              <Disclosure.Panel
                unmount={false}
                className="bg-white absolute top-0 left-0 w-full h-full z-10"
              >
                <div className="flex items-center justify-end px-4 border-b border-neutral-40 h-[4rem]">
                  <Disclosure.Button
                    className="flex items-center gap-2 w-full group"
                    onClick={() => onClose && onClose()}
                    onKeyDown={(event: any) =>
                      event.code == 'Enter' && onClose && onClose()
                    }
                  >
                    <ArrowLeftIcon className="h-5 w-5 text-primary-base" />

                    <div className="text-left">
                      <span className="block text-lg font-medium font-heading group-hover:underline underline-offset-4">
                        {item.title}
                      </span>
                    </div>
                  </Disclosure.Button>

                  <IconButton
                    size="md"
                    onClick={onMenuClose}
                    variant="ghost-muted"
                    className="mr-1"
                  >
                    <span className="sr-only">Lukk</span>
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </IconButton>
                </div>

                <div
                  className={clsx('px-4', {
                    'grid grid-cols-2 gap-4 py-6': item.navLayout === 'grid',
                    'flex flex-col py-4': item.navLayout === 'list',
                  })}
                >
                  {item.children?.map((subItem: TNavigationItem) => {
                    if (subItem?.children) {
                      return (
                        <DrawerItem
                          navLayout={item.navLayout}
                          closeDrawer={handleCloseDrawer}
                          onClose={() => setHasChildDrawer(false)}
                          onMenuClose={onMenuClose}
                          item={subItem}
                          key={`drawerItem_${subItem._key}`}
                          onOpen={onOpen}
                        />
                      );
                    }

                    const href =
                      subItem.internalLink &&
                      getPathFromInternalLink(subItem.internalLink, subItem);
                    if (!href) return null;

                    const image = (
                      <figure
                        className={clsx('relative overflow-hidden', {
                          'w-full h-[79px]': item.navLayout === 'grid',
                          'w-[70px] h-[48px]': item.navLayout === 'list',
                        })}
                      >
                        {subItem.image && subItem.image.croppedImageUrl && (
                          <NextImage
                            src={subItem.image.croppedImageUrl}
                            alt={subItem.image.alt || subItem.title}
                            style={{ objectFit: subItem.objectFit }}
                            className="w-auto h-auto"
                            fill
                          />
                        )}
                      </figure>
                    );

                    return (
                      <Link
                        key={`subitem_${subItem._key}`}
                        href={href}
                        prefetch={false}
                        tabIndex={!!hasChildDrawer ? -1 : undefined}
                        onClick={handleCloseDrawer}
                        className={clsx(
                          'flex items-center gap-2 hover:underline underline-offset-4',
                          {
                            'flex-col gap-2 justify-between':
                              item.navLayout === 'grid',
                            'flex-row py-2 gap-4':
                              item.navLayout === 'list' || !item.navLayout,
                          }
                        )}
                      >
                        {image}
                        <span className="w-full text-left">
                          {subItem.title}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    );
  }
);
