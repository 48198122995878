import { OmniumStoreWithStock } from '@/components/store';
import { FagmoblerInventory, FagmoblerOmniumCart } from '@fagmobler/types';

export function getCartStockStatusForStore(
  cart: FagmoblerOmniumCart,
  store: OmniumStoreWithStock,
  inventory: FagmoblerInventory | null
) {
  return cart.products?.every(
    (p) =>
      inventory &&
      store.id &&
      inventory[p.skuId] &&
      inventory[p.skuId][store.id] &&
      inventory[p.skuId][store.id].stock > 0
  );
}
