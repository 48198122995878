import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Loader } from '@googlemaps/js-api-loader';
import type { MarkerClusterer as TMarkerClusterer } from '@googlemaps/markerclusterer';

import type { OmniumStoreWithStock } from './useStoresWithStock';

export type StoreMapProps = {
  className?: string;
  center?: { lat: number; lng: number };
  currentLocation?: { lat: number; lng: number };
  zoom?: number;
  children?: React.ReactNode;
  stores?: OmniumStoreWithStock[];
  focusStore?: OmniumStoreWithStock | null;
  setFocusStore?: (store: OmniumStoreWithStock) => void;
  showControls?: boolean;
  setStore?(storeId: string): void;
  inputRef?: React.RefObject<HTMLInputElement>;
  onAutocomplete?(lat: number, lng: number): void;
};

const storeIcon = {
  anchor: { x: 20, y: 20 },
  size: { width: 40, height: 40 },
  scaledSize: { width: 40, height: 40 },
  url: '/static/map/store.default.png?v=1',
} as google.maps.Icon;

const hoverStoreIcon = {
  anchor: { x: 20, y: 20 },
  size: { width: 40, height: 40 },
  scaledSize: { width: 40, height: 40 },
  url: '/static/map/store.hover.png?v=1',
} as google.maps.Icon;

const focusStoreIcon = {
  anchor: { x: 35, y: 35 },
  size: { width: 70, height: 71 },
  scaledSize: { width: 70, height: 71 },
  url: '/static/map/store.active.png?v=1',
} as google.maps.Icon;

async function createClusters(
  map: google.maps.Map,
  markers: google.maps.Marker[]
) {
  const { MarkerClusterer } = await import('@googlemaps/markerclusterer');
  return new MarkerClusterer({
    map,
    markers,
    renderer: {
      render: ({ count, position }) => {
        const svg = window.btoa(
          `<svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.5" y="1.5" width="45" height="45" rx="22.5" fill="white"/><rect x="1.5" y="1.5" width="45" height="45" rx="22.5" stroke="black" stroke-width="3"/></svg>`
        );

        return new google.maps.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(50, 50),
          },
          label: {
            text: String(count),
            color: '#000',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Lato, sans-serif',
          },
          zIndex: 9,
          optimized: false,
        });
      },
    },
  });
}


export function StoreMap({
  className,
  center = {
    lat: 63.5234424, // Middle of norway
    lng: 16.9927847,
  },
  zoom = 3.5,
  stores,
  focusStore,
  setFocusStore,
  showControls = true,
  setStore,
  inputRef,
  onAutocomplete,
  currentLocation,
}: StoreMapProps) {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [hoveredStoreName, setHoveredStoreName] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const markers = useRef<google.maps.Marker[]>([]);
  const yourLocationMarker = useRef<google.maps.Marker | undefined>(undefined);
  const cluster = useRef<TMarkerClusterer | undefined>(undefined);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Load the map
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY ?? '',
      version: '3.49',
      libraries: ['geometry', 'places'],
    });

    loader.load().then(() => {
      setIsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center.lat, center.lng, zoom, showControls]);

  // Recenter
  useEffect(() => {
    if (!map || !isLoaded) return;

    map.setCenter(center);
  }, [center, map, isLoaded]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;
    const _map = new google.maps.Map(mapRef.current, {
      center,
      zoom,
      zoomControl:
        showControls && window.matchMedia('(min-width: 1024px)').matches,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    yourLocationMarker.current = new google.maps.Marker({
      map: _map,
      visible: true,
    });

    google.maps.event.addListener(_map, 'mousedown', () => {
      document.dispatchEvent(new CustomEvent('store-finder-map-mousedown'));
    });

    setMap(_map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, mapRef.current]);

  const storeIds = stores?.map((store) => store.id).join(',');

  useEffect(() => {
    if (!inputRef || !inputRef.current || !map) return;

    autocompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current
    );
    const autocomplete = autocompleteRef.current;

    autocomplete.setFields(['address_components', 'geometry', 'name']);
    autocomplete.setComponentRestrictions({ country: ['no'] });
    autocomplete.setOptions({});

    autocomplete.addListener('place_changed', async () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      const originLocation = place.geometry.location;

      if (originLocation) {
        map.setCenter(originLocation);
        const lat = originLocation.lat();
        const lng = originLocation.lng();
        if (onAutocomplete) onAutocomplete(lat, lng);
        if (yourLocationMarker.current) {
          yourLocationMarker.current.setPosition(originLocation);
          yourLocationMarker.current.setVisible(true);
          yourLocationMarker.current.setLabel({
            text: place.name || '',
            color: '#fff',
            fontFamily: 'Lato, sans-serif',
            fontSize: '12px',
            className: 'cap-sm text-neutral-0 mt-14',
          });
        }
      } else {
        console.warn('no location');
        yourLocationMarker.current?.setVisible(false);
      }
    });
  }, [inputRef, map, onAutocomplete, currentLocation]);

  useEffect(() => {
    if (yourLocationMarker.current) {
      if (currentLocation) {
        yourLocationMarker.current.setPosition(currentLocation);
      } else {
        yourLocationMarker.current.setVisible(false);
      }
    }
  }, [currentLocation]);

  // Show stores on map
  useEffect(() => {
    // Remove existing markers
    markers.current.forEach((marker) => {
      marker.setMap(null);
    });

    markers.current = [];

    if (cluster.current) {
      cluster.current.clearMarkers();
    }

    if (map) {
      stores?.forEach((store) => {
        if (!store) return;

        if (store.latitude && store.longitude) {
          const marker = new google.maps.Marker({
            position: {
              lat: parseFloat(store.latitude),
              lng: parseFloat(store.longitude),
            },
            map,
            title: store.name,
            icon:
              focusStore && store.name === focusStore.name
                ? focusStoreIcon
                : storeIcon,
            zIndex: 10,
            optimized: true,
          });

          marker.addListener('click', () => {
            if (setStore && store.id) {
              setStore(store.id);
            }

            if (typeof setFocusStore === 'function') {
              setFocusStore(store);
            }
          });

          marker.addListener('mouseout', () => {
            setHoveredStoreName('');
          });

          markers.current.push(marker);
        }
      });

      createClusters(map, markers.current).then(
        (result) => (cluster.current = result)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeIds, map, setHoveredStoreName, setFocusStore, focusStore?.id, center]);

  const focusStoreId = focusStore?.id;
  // Change marker icon when store is selected or hovered
  useEffect(() => {
    markers.current.forEach((marker) => {
      const markerTitle = marker.getTitle();

      if (focusStore && markerTitle === focusStore.name) {
        return marker.setIcon(focusStoreIcon);
      }

      if (markerTitle === hoveredStoreName) {
        return marker.setIcon(hoverStoreIcon);
      }

      marker.setIcon(storeIcon);
    });
  }, [focusStore, focusStoreId, hoveredStoreName]);

  // Zoom to markers
  useEffect(() => {
    if (!map || markers.current.length === 0) {
      return;
    }

    const zoomToMarkers = focusStore
      ? markers.current.filter(
          (marker) => marker.getTitle() === focusStore.name
        )
      : markers.current;

    if (zoomToMarkers.length === 1) {
      const coords = zoomToMarkers[0].getPosition() as google.maps.LatLng;
      map.panTo({
        lat: coords.lat(),
        lng: coords.lng(),
      });

      map.setZoom(17);
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    zoomToMarkers.forEach((marker) => {
      bounds.extend(marker.getPosition() as google.maps.LatLng);
    });
    map.fitBounds(bounds, 32);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusStore?.id, storeIds, map]);

  if (!isMounted) return null;

  return (
    <div
      id="map"
      ref={mapRef}
      className={clsx(className, 'store-finder-map h-full w-full')}
      suppressHydrationWarning={true}
    />
  );
}

export default StoreMap;
