import { ClientConfig } from '@sanity/client';

export const sanityConfig: ClientConfig = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET!,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!,
};

export const clientConfig: ClientConfig = {
  ...sanityConfig,
  apiVersion: 'v2022-03-13',
};
