import { useQuery } from 'react-query';
import type { OmniumStore } from '@fagmobler/omnium';
import { HOST } from '@/lib/host';

export async function fetchStore(storeId: string): Promise<OmniumStore | null> {
  const res = await fetch(`${HOST}/api/stores/${storeId}`);

  if (res.status !== 200) return null;

  const data = (await res.json()) as OmniumStore;

  return data;
}

export type UseStoreProps = {
  initialData?: OmniumStore | null;
  enabled?: boolean;
};

export function useStore(
  storeId: string | null | undefined,
  { initialData, enabled }: UseStoreProps = {
    initialData: null,
    enabled: true,
  }
) {
  return {
    store: useQuery<OmniumStore | null>(
      'store',
      () => (storeId && fetchStore(storeId)) || null,
      { initialData, enabled }
    ),
  };
}
