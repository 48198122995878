import clsx from 'clsx';

import type { OmniumStore } from '@fagmobler/omnium';

export type StoreContactProps = {
  store: OmniumStore;
};

/**
 * StoreContact component
 *
 * @returns {JSX}
 */
export function StoreContact({ store }: StoreContactProps) {
  return (
    <div>
      <h3 className={clsx('hd-lg', 'mb-1')}>Kontakt</h3>
      <address className="not-italic">
        <div className="flex justify-between">
          <label>Telefon</label>
          <a href={`tel:${store.phoneNumber}`}>{store.phoneNumber}</a>
        </div>
        <div className="flex justify-between">
          <label>E-post</label>
          <a href={`mailto:${store.email}`}>{store.email}</a>
        </div>
      </address>
    </div>
  );
}

export default StoreContact;
