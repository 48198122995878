import { Bars3Icon } from '@heroicons/react/24/outline';
import type { TNavigationItem } from '@/lib/sanity/queries';
import { useNavigation } from '@/contexts/navigation';
import clsx from 'clsx';
import { useState } from 'react';

export function MobileNavigationToggle() {
  const {
    mainNavigation = [],
    setShowMainNavigation,
    showMainNavigation,
  } = useNavigation();

  const [selectedMainItem, setSelectedMainItem] =
    useState<TNavigationItem | null>(null);

  const onClose = (item: TNavigationItem | null) => {
    setSelectedMainItem(item);
  };

  const mainItem: TNavigationItem = {
    title: 'Meny',
    _key: 'mainMenu',
    _type: 'mainMenu',
    navType: 'primary',
    navLayout: 'list',
    objectFit: 'contain',
    showInHeader: false,
    children: [
      ...mainNavigation
        .filter(
          (item) =>
            item.navType === 'primary' &&
            item.showInHeader &&
            !!item.children?.length
        )
        .map((item) => ({ ...item, _type: 'mainMenuItem' })),
      {
        title: '',
        _key: 'test',
        _type: 'mainMenuItem',
        navType: 'secondary',
        navLayout: 'list',
        objectFit: 'contain',
        children: mainNavigation
          .filter(
            (item) => item.navType !== 'tertiary' && item.navType !== 'primary'
          )
          .map((item) => ({ ...item, _type: 'mainMenuItem' })),
      },
    ],
  };

  return (
    <button
      id="mainMenuButton"
      title="Åpne meny"
      className={clsx(
        'block lg:hidden bg-neutral-80 rounded-full p-3 flex items-center',
        'focus:outline-none focus-visible:ring-4 hover:underline underline-offset-4',
        {
          underline: selectedMainItem?._key === mainItem._key,
        }
      )}
      onKeyDown={(event: any) => {
        if (event.code === 'Escape') {
          setShowMainNavigation(!showMainNavigation);
          onClose(mainItem);
        }
      }}
      onClick={() => {
        setShowMainNavigation(!showMainNavigation);
      }}
    >
      <Bars3Icon className="h-5" />
    </button>
  );
}

export default MobileNavigationToggle;
