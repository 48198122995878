export default function sanityLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  try {
    const url = new URL(src);
    url.searchParams.set('auto', 'format');
    url.searchParams.set('fit', 'max');
    url.searchParams.set('w', width.toString());
    // if (quality) {
    // url.searchParams.set('q', quality.toString());
    // }
    url.searchParams.set('q', '90');
    // url.searchParams.set('dpr', '2');
    return url.href;
  } catch (e) {
    return src;
  }
}
