import Link from 'next/link';
import { ProductListGridItem } from '@fagmobler/ui';
import {
  FagmoblerInventory,
  type FagmoblerAlgoliaProduct,
  type FagmoblerAlgoliaProductVariant,
  type AlgoliaProduct_fagmobler_try,
} from '@fagmobler/types';
import { getCanonicalPath } from '@/lib/algolia/getCanonicalPath';
import { useStores } from '@/components/store/useStores';
import { AlgoliaHit } from 'instantsearch.js';
import Image from 'next/image';
import { missingImageUrls } from '@/lib/utils/missingImageUrls';
import { useQuery } from 'react-query';
import { getStorePath } from '@/lib/omnium/getStoreUrl';
import { MouseEvent, useContext } from 'react';
import { NavigationContext } from '@/contexts/navigation';
import { getTagsForDisplay } from '@/lib/algolia/tags';
import { useRouter } from 'next/compat/router';

export type ProductItemProps = {
  product: FagmoblerAlgoliaProduct | FagmoblerAlgoliaProductVariant;
  hit?: AlgoliaHit;
  priority?: boolean;
  onClick?(): void;
  queryID?: string;
  imageSizes: string;
  showImage?: boolean;
};

export const ProductGridItem = ({
  product,
  priority = false,
  onClick,
  queryID,
  imageSizes,
  showImage = true,
}: ProductItemProps) => {
  // Hack to prevent searchServerState from loading images
  const __isInNavigationContext = useContext(NavigationContext);

  const {
    current: { data: { store = {} } = {} },
  } = useStores();
  const router = useRouter();

  const inventoryResult = useQuery<FagmoblerInventory>(
    `inventory_${product.objectID}`,
    {
      enabled: false,
    }
  );

  if (!product) {
    return <div>Ingen produktinfo</div>;
  }

  const { image, objectID } = product;

  const hasImage = image && !missingImageUrls.includes(image);

  if (!objectID) {
    console.error('Missing objectID');
    return null;
  }
  const asPath = getCanonicalPath(product);

  if (!asPath) return null;

  const hasDescription = (p: any): p is FagmoblerAlgoliaProduct => {
    return !!p.posterDescription;
  };

  let alt = product.posterName
    ? `Bilde av ${product.posterName}`
    : 'Bilde av produkt';

  if (hasDescription(product)) {
    alt = product.posterName
      ? `Bilde av ${product.posterName} (${product.posterDescription})`
      : 'Bilde av produkt';
  }
  const itemInventory = store?.id
    ? inventoryResult.data?.[product.objectID]?.[store.id]
    : undefined;

  const tags = product.hasOwnProperty('tags')
    ? (product as AlgoliaProduct_fagmobler_try).tags !== undefined
      ? (product as AlgoliaProduct_fagmobler_try).tags
      : []
    : [];

  const handleStoreClick = (event: MouseEvent) => {
    if (!store) return;
    const storeUrl = getStorePath(store);
    if (storeUrl && router) {
      router.push(storeUrl);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <Link
      href={{
        pathname: asPath,
        query: queryID ? { queryID } : undefined,
      }}
      scroll={true}
      as={asPath}
      prefetch={false}
      onClick={onClick}
    >
      <ProductListGridItem
        key={objectID}
        product={product}
        tags={getTagsForDisplay(tags, 2)}
        store={store}
        inventory={itemInventory}
        showImage={showImage}
        onStoreClick={handleStoreClick}
        image={
          hasImage &&
          // Hack to prevent searchServerState from loading images
          __isInNavigationContext && (
            <Image
              className="mix-blend-darken object-contain w-auto h-auto"
              alt={alt}
              src={image}
              priority={priority}
              fill
              sizes={imageSizes}
            />
          )
        }
      />
    </Link>
  );
};

export default ProductGridItem;
