import { getCategoriesResult } from '@/lib/categories/getCategories';
import Link from 'next/link';
import Image from 'next/image';
import { SimpleProductCategoryEnrichment } from '@fagmobler/sanity';

export const ProductCategories = ({
  categories,
}: {
  categories: getCategoriesResult | SimpleProductCategoryEnrichment[] | null;
}) => {
  if (!categories) return null;

  return (
    <ul className="grid gap-4 my-4 grid-cols-2 md:grid-cols-[repeat(auto-fit,minmax(100px,216px))]">
      {categories.map((category) => {
        let enrichment = undefined;

        if ('enrichment' in category) enrichment = category.enrichment;
        if ('_id' in category && category._type == 'productCategoryEnrichment')
          enrichment = category;

        if (!enrichment) return null;

        const slug =
          ('properties' in category &&
            category.properties?.find((prop) => prop.key === 'slug')?.value) ||
          enrichment?.algoliaProductCategorySlug?.split(' > ').join('/');

        if (!slug) {
          return null;
        }

        const image = enrichment?.mainImage;
        const title =
          enrichment?.title ||
          ('name' in category && category.name) ||
          'Kategori';

        const omniumId = enrichment?.omniumId;
        const hidden = enrichment?.hidden;
        const topLevel = enrichment?.topLevelOmniumId;

        const topSlug = topLevel === 'AG' ? 'produkter' : 'rom';

        let url = `/${topSlug}/${slug}`;
        const imageUrl = image?.croppedImageUrl || image?.url;

        const key =
          'categoryId' in category ? category.categoryId : enrichment?._id;

        if (hidden) return null;

        return (
          <li key={key} data-omnium-id={omniumId}>
            <Link href={url} title={title}>
              {image && imageUrl ? (
                <>
                  <Image
                    src={imageUrl}
                    alt={image.alt || title}
                    width={image.width || 500}
                    height={image.height || 500}
                    className="object-cover w-full aspect-video"
                    sizes="200px"
                    placeholder={image.lqip ? 'blur' : undefined}
                    blurDataURL={image.lqip || undefined}
                  />
                  <span className="cap-base">{title}</span>
                </>
              ) : (
                <div className="cap-base flex items-center text-center justify-center w-full bg-neutral-60 text-neutral-0 uppercase aspect-video">
                  {title} ({omniumId})
                </div>
              )}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
