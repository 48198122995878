import { useCallback, useMemo } from 'react';

import type { OmniumStore } from '@fagmobler/omnium';

import { defaultRegion } from '@/lib/no-regions';

import type { Region } from './useStores';
import type { StoresByRegion } from './useStoresByRegion';

export function useStoresInRegion<T extends OmniumStore>(
  storesByRegion: StoresByRegion<T>,
  selectedRegion?: Region | null
): T[] {
  const getExtraStores = useCallback(
    (selectedRegion: Region | null, storesByRegion: any) => {
      let extraStores: T[] = [];
      // Add viken to oslo
      // if (selectedRegion?.iso === '03' && storesByRegion['30']) {
      //   extraStores = [...extraStores, ...storesByRegion['30'].stores];
      // }
      return extraStores;
    },
    []
  );

  return useMemo(
    () =>
      selectedRegion
        ? (storesByRegion[selectedRegion.iso] && [
            ...storesByRegion[selectedRegion.iso].stores,
            ...getExtraStores(selectedRegion, storesByRegion),
          ]) ??
          []
        : [],
    [storesByRegion, selectedRegion, getExtraStores]
  );
}

export default useStoresInRegion;
