import React from 'react';

function InstagramIcon({ className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 25"
      className={className}
    >
      <path
        fill="#fff"
        d="M16.375 3.5a4.388 4.388 0 014.375 4.375v8.75A4.388 4.388 0 0116.375 21h-8.75a4.389 4.389 0 01-4.375-4.375v-8.75A4.388 4.388 0 017.625 3.5h8.75zm0-1.75h-8.75C4.256 1.75 1.5 4.506 1.5 7.875v8.75c0 3.369 2.756 6.125 6.125 6.125h8.75c3.369 0 6.125-2.756 6.125-6.125v-8.75c0-3.369-2.756-6.125-6.125-6.125z"
      ></path>
      <path
        fill="#fff"
        d="M17.688 7.875a1.313 1.313 0 11-.072-2.626 1.313 1.313 0 01.072 2.626zM12 8.75a3.5 3.5 0 110 7 3.5 3.5 0 010-7zM12 7a5.25 5.25 0 100 10.5A5.25 5.25 0 0012 7z"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
