import React from 'react';

function FacebookIcon({ className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 25"
      className={className}
    >
      <path
        fill="#fff"
        d="M13.397 21.247V13.05h2.765l.411-3.21h-3.176V7.799c0-.926.258-1.56 1.587-1.56h1.684v-2.86a22.107 22.107 0 00-2.467-.128c-2.444 0-4.122 1.492-4.122 4.23v2.356H7.332v3.21h2.753v8.2h3.312z"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
