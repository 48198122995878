import clsx from 'clsx';

import type { OmniumStore } from '@fagmobler/omnium';

export type StoreAddressProps = {
  store: OmniumStore;
};

/**
 * StoreAddress component
 *
 * @returns {JSX}
 */
export function StoreAddress({ store }: StoreAddressProps) {
  return (
    <address className={clsx('not-italic')}>
      <h3 className={clsx('hd-md', 'mb-2')}>{store.name}</h3>
      <p className={clsx('body-sm', 'text-neutral-70')}>
        {store.address?.streetName} {store.address?.streetNumber},{' '}
        {store.address?.zipcode} {store.address?.city}
      </p>
    </address>
  );
}

export default StoreAddress;
