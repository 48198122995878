import {
  FagmoblerAlgoliaProduct,
  FagmoblerAlgoliaProductVariant,
} from '@fagmobler/types';
import { getCategoryRoot } from './utils';

export const getCanonicalPath = (
  product: FagmoblerAlgoliaProduct | FagmoblerAlgoliaProductVariant
) => {
  const { categorySlugs, objectID } = product;

  const categoryRoot = getCategoryRoot('categories');

  if (!categorySlugs) {
    return `${categoryRoot}/${objectID}`; // Fallback to SKU
  }

  // Find category slugs from algolia product
  const selectedCategoryKey = Object.keys(categorySlugs).slice(-1)[0];
  if (selectedCategoryKey) {
    const selectedCategory = categorySlugs[selectedCategoryKey];
    if (selectedCategory && typeof selectedCategory === 'string') {
      const slugs = selectedCategory.split(' > ').join('/');
      return `${categoryRoot}/${slugs}/${objectID}`;
    }
    if (selectedCategory && typeof selectedCategory === 'object') {
      const deepestCategory = selectedCategory.slice(-1)[0];
      if (typeof deepestCategory === 'string') {
        const slugs = deepestCategory.split(' > ').join('/');
        return `${categoryRoot}/${slugs}/${objectID}`;
      }
    }
    console.error('Could not create canonical. Missing categories', objectID);
  }
  return `${categoryRoot}/${objectID}`;
};
