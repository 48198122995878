import { MaterializedImageCollectionBlock } from '@fagmobler/sanity';
import clsx from 'clsx';
import ImageCarousel, { TImage } from '../imageCarousel';
import ImageWithCaption from './DefaultImage';

type ImageCollectionProps = {
  title: MaterializedImageCollectionBlock['title'];
  images: MaterializedImageCollectionBlock['images'];
  aspectRatio: MaterializedImageCollectionBlock['aspectRatio'];
};

const MAX_IMAGES = 6;

const getImageSize = (imageCount: number, aspectRatio?: number) => {
  const sizes = [
    { width: 460, height: 460, media: '(max-width: 640px) 100vw, 460px' },
    { width: 1152, height: 1152, media: '(max-width: 640px) 100vw, 1152px' },
    { width: 576, height: 576, media: '(max-width: 640px) 100vw, 576px' },
    { width: 380, height: 380, media: '(max-width: 640px) 100vw, 380px' },
  ];

  const size = sizes[imageCount] || sizes[0];

  if (aspectRatio) {
    return {
      ...size,
      height: size.width / aspectRatio,
    };
  }

  return size;
};

export const ImageCollection = ({
  title,
  images,
  aspectRatio,
}: ImageCollectionProps) => {
  const imageSize = getImageSize(images?.length, aspectRatio);

  return (
    <div className="relative w-full">
      {title && <h1 className="hd-xl mb-5">{title}</h1>}
      {images?.length <= 6 && (
        <div
          className={clsx('grid', 'gap-2 md:gap-4', {
            'md:grid-cols-2': images.length === 2 || images.length === 2,
            'md:grid-cols-3': images.length === 3 || images.length === 6,
          })}
        >
          {images?.length <= MAX_IMAGES &&
            images
              ?.filter((image) => !!image && image.croppedImageUrl)
              .map((image) => (
                <div
                  key={image._key}
                  className={clsx('relative', {
                    'aspect-square': aspectRatio === 1,
                    'aspect-[4/3]': aspectRatio == 4 / 3,
                    'aspect-[4/5]': aspectRatio == 4 / 5,
                  })}
                >
                  <ImageWithCaption
                    src={image.croppedImageUrl as string}
                    fill
                    alt={image.alt || title || 'Bilde'}
                    placeholder={image.lqip ? 'blur' : undefined}
                    blurDataURL={image.lqip || undefined}
                    width={imageSize.width}
                    height={imageSize.height}
                    sizes={imageSize.media}
                    forceHeight={true}
                  />
                </div>
              ))}
        </div>
      )}
      {images?.length > MAX_IMAGES && (
        <ImageCarousel
          alt={[title || '']}
          images={images.map(
            (image) =>
              ({
                url: image.croppedImageUrl || image.url,
                key: image._key,
              } as TImage)
          )}
        />
      )}
    </div>
  );
};
