import { useState } from 'react';
import { Page } from '@fagmobler/sanity';
import { configuredSanityClient } from '@/lib/sanity/sanityClient';
import { Loading } from '@fagmobler/ui';
import { FagmoblerPortableText } from '@/components/portableText';

export function MemberTerms() {
  const [page, setPage] = useState<Page | null>(null);

  configuredSanityClient
    .fetch<Page>('*[_id == "e4f7a683-d1ec-4c49-8e1c-b555fb6a1c32"][0]')
    .then((res) => {
      setPage(res);
    });

  if (!page || !page.body) return <Loading />;

  return (
    <section>
      <div className="p-5 body-sm">
        <FagmoblerPortableText value={page.body} />
      </div>
    </section>
  );
}

export default MemberTerms;
