// @ts-ignore
import jsonsize from 'json-size';
import dynamic from 'next/dynamic';
import type { ReactJsonViewProps } from 'react-json-view';

type DebugProps = {
  label?: string;
  data?: object;
  children?: never;
};

const Debug = ({ data, label }: DebugProps) => {
  if (process.env.NODE_ENV !== 'development') return null;

  const ReactJson = dynamic(() => import('react-json-view'), {
    ssr: false,
  }) as (props: ReactJsonViewProps) => JSX.Element;

  return (
    <>
      <h2 className="mt-20 text-lg">
        <strong>{label}</strong> Data size{' '}
        <code>{Object.keys({ data })[0]}</code>{' '}
        {Math.round(jsonsize(data) / 1024)} kB
      </h2>
      <ReactJson src={data || {}} name={label} collapsed />
      {/* <pre className="rounded-md p-5 my-5 text-xs bg-gray-200 overflow-scroll"></pre> */}
    </>
  );
};

export default Debug;
