import client from '@sanity/client';
import { clientConfig } from './config';

export const configuredSanityClient = client({
  ...clientConfig,
  useCdn: true,
});

export const configuredSanityClientWithoutCdn = client({
  ...clientConfig,
  useCdn: false,
});

export const configuredSanityClientWithCredentials = client({
  ...clientConfig,
  useCdn: false,
  withCredentials: true,
});

export const sanityServerClient = client({
  ...clientConfig,
  useCdn: false,
  withCredentials: false,
  token: process.env.SANITY_API_READ_TOKEN,
});

export const sanityServerClientPrivate = client({
  ...clientConfig,
  dataset: 'private',
  useCdn: false,
  withCredentials: false,
  token: process.env.SANITY_WRITE_TOKEN,
});
