import { TNavigationItem } from '@/lib/sanity/queries';
import { useNavigation } from '@/contexts/navigation';
import { useEffect, useState } from 'react';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';
import Link from 'next/link';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Popover } from '@headlessui/react';
import { MegaMenuPanel } from './MegaMenuPanel';

export const MegaMenu = () => {
  const {
    mainNavigation = [],
    setShowMainNavigation,
    showMainNavigation,
  } = useNavigation();

  const [selectedMainItem, setSelectedMainItem] =
    useState<TNavigationItem | null>(null);

  useEffect(() => {
    document.body.style.overflow = showMainNavigation ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [showMainNavigation]);

  const onClose = (item: TNavigationItem | null) => {
    setSelectedMainItem(item);
  };

  return (
    <ul className="flex items-center gap-4 text-base justify-around w-full relative h-[55px]">
      {mainNavigation
        .filter((item) => item.navType === 'primary' && item.showInHeader)
        .map((item: TNavigationItem) => {
          const href =
            item.internalLink &&
            getPathFromInternalLink(item.internalLink, item);

          if (!item.children && href) {
            return (
              <Link
                href={href}
                key={`header_item_${item._key}`}
                onClick={() => {
                  onClose(null);
                  setShowMainNavigation(false);
                }}
                className={clsx(
                  'flex items-center font-medium text-sm rounded py-3',
                  'focus:outline-none ring-offset-focus focus-visible:ring-4',
                  'hover:underline'
                )}
              >
                {item.title}
              </Link>
            );
          }

          return (
            <Popover as="li" key={`disclosureButton_${item._key}`}>
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={clsx(
                      'flex gap-1 items-center rounded',
                      'font-medium py-2 text-sm',
                      'hover:underline underline-offset-4',
                      'focus:outline-none ring-offset-focus focus-visible:ring-4',
                      {
                        underline: selectedMainItem?._key === item._key,
                      }
                    )}
                    onClick={() => {
                      onClose(item);
                      setShowMainNavigation(true);
                    }}
                  >
                    {item.title}
                    <ChevronDownIcon className="w-5 h-5" />
                  </Popover.Button>
                  <MegaMenuPanel
                    item={item}
                    onClose={(item) => {
                      onClose(item);
                      close();
                    }}
                    open={open}
                  />
                </>
              )}
            </Popover>
          );
        })}
      {mainNavigation
        .filter((item) => item.navType !== 'primary')
        .slice(0, 3)
        .map((item: TNavigationItem, i) => {
          const href =
            item.internalLink &&
            getPathFromInternalLink(item.internalLink, item);
          if (!href) return null;
          return (
            <li key={item._key} className={clsx({ 'ml-auto': i === 0 })}>
              <Link
                href={href}
                className={clsx(
                  'flex items-center rounded py-3',
                  'focus:outline-none ring-offset-focus focus-visible:ring-4',
                  'hover:underline text-sm'
                )}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};
