import clsx from 'clsx';

import type { OmniumStore } from '@fagmobler/omnium';
import { Button, Sticker } from '@fagmobler/ui';

import { StoreAddress } from './StoreAddress';
import { StoreOpeningHours } from './StoreOpeningHours';
// import Image from 'next/image';

export type StoreDetailsProps = {
  store: OmniumStore;
  isSelected: boolean;
  onStoreChange: () => void;
  onShowStores: () => void;
};

/**
 * StoreDetails component
 *
 * @returns {JSX}
 */
export function StoreDetails({
  store,
  isSelected,
  onStoreChange,
  onShowStores,
}: StoreDetailsProps) {
  return (
    <>
      {isSelected && (
        <div className={clsx('mb-2')}>
          <Sticker size="sm">Valgt som min butikk</Sticker>
        </div>
      )}

      <StoreAddress store={store} />

      {!isSelected && (
        <div className={clsx('my-4')}>
          <Button
            className={clsx(
              'w-full',
              'items-center',
              'justify-center',
              'text-base'
            )}
            onClick={onStoreChange}
          >
            Velg butikk
          </Button>
        </div>
      )}
      <div className={clsx('mt-4', 'mb-8')}>
        {/* <Button
          className={clsx('mr-4')}
          variant="outline"
          onClick={() => {
            console.log('clicked');
          }}
        >
          Åpne i Google Maps
        </Button> */}
        {isSelected && (
          <Button variant="outline" onClick={onShowStores}>
            Endre butikk
          </Button>
        )}
      </div>

      <StoreOpeningHours store={store} compact={false} />

      {/*store.imageUrl && (
        <div className={clsx('mt-8', 'mb-2')}>
          <Image
            src={store.imageUrl}
            layout="fill"
            alt={`Bilde fra ${store.name}`}
          />
        </div>
      )*/}

      {store.description && (
        <div
          className={clsx('mb-2')}
          dangerouslySetInnerHTML={{ __html: store.description }}
        ></div>
      )}

      <div className={clsx('mt-4', 'mb-2')}>
        <h4 className={clsx('mb-2')}>Kontakt</h4>
        {store.phoneNumber && (
          <p className={clsx('flex', 'justify-between')}>
            <span>Telefon</span> <span>{store.phoneNumber}</span>
          </p>
        )}
        {store.email && (
          <p className={clsx('flex', 'justify-between')}>
            <span>E-postadresse</span> <span>{store.email}</span>
          </p>
        )}
      </div>
    </>
  );
}

export default StoreDetails;
