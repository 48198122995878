import clsx from 'clsx';
import React, { RefObject, useEffect, useRef } from 'react';
import Image from 'next/image';
import { TImage } from '.';
import { missingImageUrls } from '@/lib/utils/missingImageUrls';
import { ImageMissing } from '@fagmobler/ui';

type CarouselImageProps = {
  containerRef: RefObject<HTMLDivElement>;
  currentImage: TImage;
  image: TImage;
  alt: string;
  priority?: boolean;
  onClick(event: any, image: TImage): void;
  sizes?: string;
  className?: string;
};

export const CarouselImage = ({
  image,
  containerRef,
  currentImage,
  priority,
  alt,
  sizes,
  onClick,
  className,
}: CarouselImageProps) => {
  const imageRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (currentImage === image && imageRef.current && containerRef.current) {
      const left = imageRef.current.offsetLeft;
      containerRef.current.scrollTo({ left });
    }
  }, [containerRef, currentImage, image]);

  const hasImage = image && image.url && !missingImageUrls.includes(image.url);

  return (
    <a
      ref={imageRef}
      key={image.url}
      href={image.url}
      onClick={(event) => onClick(event, image)}
      className={clsx(
        'flex',
        'justify-center',
        'items-center',
        'relative',
        'bg-neutral-30',
        'block',
        'rounded',
        'p-5',
        'aspect-square',
        'h-full',
        'snap-center',
        'print:bg-[transparent]',
        'print:p-0',
        'mx-5',
        {
          'print:hidden': currentImage.url !== image.url,
        }
      )}
    >
      {hasImage && (
        <Image
          className="mix-blend-darken print:mix-blend-normal p-5 object-contain"
          src={image.url}
          alt={alt}
          sizes={sizes}
          priority={priority}
          fill
          placeholder={image.lqip ? 'blur' : undefined}
          blurDataURL={image.lqip || undefined}
        />
      )}
      {!hasImage && <ImageMissing size={'lg'} />}
    </a>
  );
};

export default CarouselImage;
