import clsx from 'clsx';

/**
 * ShowStoreInfoButton component
 *
 * @returns {JSX}
 */
export function ShowStoreInfoButton({
  children,
  onClick,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  function handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (typeof onClick === 'function') {
      onClick(e);
    }
  }

  return (
    <button
      className={clsx('text-primary-base', 'underline')}
      onClick={handleOnClick}
      {...rest}
    >
      {children || 'Vis mer informasjon'}
    </button>
  );
}

export default ShowStoreInfoButton;
