type imageSizesProps = {
  base: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
};

const tailwindWidths = {
  xxl: '1536px',
  xl: '1280px',
  lg: '1024px',
  md: '768px',
  sm: '640px',
  base: '',
};

export function imageSizes(props: imageSizesProps): string {
  let sizes = '';

  Object.keys(tailwindWidths).forEach((size) => {
    if (!props[size as keyof typeof tailwindWidths]) return;

    const breakpoint = tailwindWidths[size as keyof typeof tailwindWidths];
    const value = props[size as keyof typeof tailwindWidths];

    if (size === 'base') {
      sizes += `${value} `;
    } else {
      sizes += `(min-width: ${breakpoint}) ${value}, `;
    }
  });

  return sizes;
}
