import {
  FagmoblerAlgoliaProduct,
  FagmoblerPublicOmniumProduct,
} from '@fagmobler/types';

import { getFlattenedAlgoliaProductCategories } from '@/lib/algolia/utils';
import { OmniumCart, OmniumOrderLine } from '@fagmobler/omnium';

export type ConvertLineitemToDatalayerArgs = {
  lineItem?: OmniumOrderLine;
  omniumProduct?: FagmoblerPublicOmniumProduct;
  algoliaProduct?: FagmoblerAlgoliaProduct;
};

export type ConvertLineitemIntoEcommerceObject = {
  lineItem?: OmniumOrderLine;
  omniumProduct?: FagmoblerPublicOmniumProduct;
  algoliaProduct?: FagmoblerAlgoliaProduct;
};

export type DataLayerEcommerceProduct = {
  item_id?: string;
  item_name?: string;
  price?: number;
  quantity?: number;
  coupon?: string;
  location_id?: string;
};

export type EcommerceObject = {
  currency?: 'NOK';
  value?: number; // price
  store_id?: string;
  store_name?: string;

  // lists
  item_list_name?: string;

  // promotions
  creative_name?: string;
  creative_slot?: string;
  promotion_id?: string;
  promotion_name?: string;

  // purchase
  transaction_id?: string;
  tax?: number;
  shipping?: number;
  // login: "yes",

  // products
  items?: (DataLayerEcommerceProduct | undefined)[];

  // search
  search_term?: string;
};

export type DataLayer = {
  path?: string;
  event?:
    | 'view_page'
    | 'view_item_list'
    | 'select_item'
    | 'view_item'
    | 'view_promotion'
    | 'select_promotion'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'view_cart'
    | 'begin_checkout'
    | 'add_payment_info'
    | 'add_shipping_info'
    | 'site_search'
    // | 'begin_clickandcollect_checkout'
    | 'clickandcollect_checkout'
    | 'purchase';

  ecommerce?: EcommerceObject | null;
};

export function convertLineItemIntoDatalayerProductItem({
  lineItem,
  omniumProduct,
  algoliaProduct,
}: ConvertLineitemToDatalayerArgs): DataLayerEcommerceProduct | undefined {
  if (!lineItem && !omniumProduct && !algoliaProduct) {
    return undefined;
  }

  // const categories = algoliaProduct
  //   ? getFlattenedAlgoliaProductCategories(algoliaProduct)
  //   : [];

  return {
    item_id: lineItem?.code || omniumProduct?.skuId || undefined,
    // item_name: lineItem?.displayName || omniumProduct?.name || algoliaProduct?.posterName || undefined,
    // affiliation: undefined, // 'Google Merchandise Store'
    // coupon: undefined, // 'SUMMER_FUN'
    // currency: 'NOK', // 'USD'
    // discount: (lineItem?.discounted ?? 0) / (lineItem?.quantity ?? 1),
    // index: undefined, // 0
    // item_brand: lineItem?.supplierName || algoliaProduct?.supplier, // 'Google'
    // item_category: categories[0], // 'Apparel'
    // item_category2: categories[1], // 'Adult'
    // item_category3: categories[2], // 'Shirts'
    // item_category4: categories[3], // 'Crew'
    // item_category5: categories[4], // 'Short sleeve'
    // item_list_id: undefined, // 'related_products'
    // item_list_name: undefined, // 'Related Products'
    // item_variant:
    //   lineItem?.code && lineItem?.code !== omniumProduct?.skuId
    //     ? lineItem?.code
    //     : omniumProduct?.skuId, // 'green'
    // location_id: undefined, // 'ChIJIQBpAG2ahYAR_6128GcTUEo'
    price:
      ((lineItem?.discountedPrice ||
        lineItem?.placedPrice ||
        lineItem?.extendedPrice) ??
        0) / (lineItem?.quantity ?? 1), // 9.99
    quantity: lineItem?.quantity ?? 1, // 1
  };
}

export function convertLineitemIntoEcommerceObject({
  lineItem,
  omniumProduct,
  algoliaProduct,
}: ConvertLineitemIntoEcommerceObject): EcommerceObject {
  const price =
    lineItem?.discountedPrice || omniumProduct?.price?.unitPrice || 0;
  return {
    currency: 'NOK', // "USD"
    value: price * (lineItem?.quantity ?? 1),
    items: [
      convertLineItemIntoDatalayerProductItem({
        lineItem,
        omniumProduct,
        algoliaProduct,
      }),
    ].filter(Boolean),
  };
}

export function getDatalayer(
  event: DataLayer['event'],
  ecommerceObject: DataLayer['ecommerce']
) {
  return {
    event,
    ecommerce: ecommerceObject,
  };
}

export function convertCartDataIntoEcommerceObject({
  cart,
  omniumProducts,
  algoliaProducts,
}: {
  cart: OmniumCart;
  omniumProducts?: FagmoblerPublicOmniumProduct[];
  algoliaProducts?: FagmoblerAlgoliaProduct[];
}): EcommerceObject {
  return {
    currency: 'NOK',
    value: cart.orderForm?.subTotal,
    items: ((cart.orderForm?.lineItems ?? []) as OmniumOrderLine[])
      .map((lineItem) =>
        convertLineItemIntoDatalayerProductItem({
          lineItem,
          algoliaProduct: Array.isArray(algoliaProducts)
            ? algoliaProducts.find(
                (product) => product.objectID === lineItem.code
              )
            : undefined,
          omniumProduct: Array.isArray(omniumProducts)
            ? omniumProducts.find((product) => product.skuId === lineItem.code)
            : undefined,
        })
      )
      .filter(Boolean) as DataLayerEcommerceProduct[],
  };
}
