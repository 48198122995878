import clsx from 'clsx';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import type {
  Breadcrumb as TBreadcrumb,
  Breadcrumbs as TBreadcrumbs,
} from '@fagmobler/types';
import Link from 'next/link';
import { ReactNode } from 'react';

export function BreadcrumbList({ children }: { children: ReactNode }) {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="flex items-center sm:space-x-1.5">{children}</ol>
    </nav>
  );
}

export function BreadcrumbListItem({
  isFirst,
  isCurrent,
  children,
}: {
  isFirst?: boolean;
  isCurrent?: boolean;
  children: React.ReactNode;
}) {
  return (
    <li
      className={clsx(
        'flex',
        isCurrent && 'flex-1',
        'min-w-0',
        'items-center',
        'whitespace-nowrap'
      )}
    >
      {!isFirst && (
        <ChevronRightIcon
          className={clsx(
            'flex-shrink-0',
            'h-5 w-5',
            'text-neutral-70',
            'text-sm'
          )}
          aria-hidden="true"
        />
      )}

      {children}
    </li>
  );
}

export function BreadcrumbItem({
  name,
  isCurrent,
  path,
  isFirst,
}: TBreadcrumb & { isFirst?: boolean }) {
  return (
    <BreadcrumbListItem isCurrent={isCurrent} isFirst={isFirst}>
      {isCurrent ? (
        <span
          className={clsx(
            'flex-0',
            !isFirst && 'sm:ml-1.5',
            'overflow-hidden overflow-ellipsis',
            'text-neutral-80',
            'underline text-sm font-medium underline-offset-2'
          )}
        >
          {name}
        </span>
      ) : (
        <Link
          href={path}
          className={clsx(
            'min-w-0',
            !isFirst && 'sm:ml-1.5',
            'whitespace-nowrap',
            'overflow-hidden overflow-ellipsis',
            'hover:underline text-neutral-70 text-sm font-medium underline-offset-2'
          )}
          aria-current={isCurrent ? 'page' : undefined}
        >
          {name}
        </Link>
      )}
    </BreadcrumbListItem>
  );
}

export interface BreadcrumbsProps {
  breadcrumbs: TBreadcrumbs;
}

export function Breadcrumbs({ breadcrumbs = [] }: BreadcrumbsProps) {
  const crumbs = breadcrumbs.filter((crumb) => crumb.path !== '/');
  return (
    <BreadcrumbList>
      <BreadcrumbItem isFirst name="Fagmøbler.no" path="/" />
      {crumbs.map((crumb, i) => (
        <BreadcrumbItem
          key={i}
          {...crumb}
          isCurrent={i === crumbs.length - 1}
        />
      ))}
    </BreadcrumbList>
  );
}

export default Breadcrumbs;
