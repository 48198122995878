import { useState } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { Input, Select, Button, Checkbox } from '@fagmobler/ui';

import { useStores, useStoresByRegion } from '@/components/store';
import Link from 'next/link';
import { useNavigation } from '@/contexts/navigation';

export function ClubMemberForm() {
  const {
    current: { data: { store = null } = {} },
    stores: { data: stores = [] },
  } = useStores();

  const {
    register,
    handleSubmit,
    resetField,
    setError,
    formState: { errors },
  } = useForm();

  const [success, setSuccess] = useState(false);

  const storesByRegion = useStoresByRegion(stores);
  const { setShowClubMemberForm } = useNavigation();

  async function onSubmit(data: Record<string, string>) {
    const res = await fetch('/api/club/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await res.json();

    if (result.errors) {
      const errorKeys = Object.keys(result.errors);

      if (errorKeys.length) {
        errorKeys.forEach((key) => {
          setError(
            key,
            { message: result.errors[key].join('') },
            { shouldFocus: true }
          );
        });
      }

      return;
    }

    setSuccess(true);
  }

  return (
    <section className="p-4 md:p-6">
      {success ? (
        <div className="flex justify-center items-center">
          <p>Du er nå medlem i kundeklubben!</p>
        </div>
      ) : (
        <>
          <p className={clsx('body-base', 'text-neutral-70', 'mb-6')}>
            Om du er som oss, opptatt av møbler, interiør, farger, trender og i
            tillegg vektlegger pris og kvalitet - er det et smart valg å bli
            medlem i kundeklubben! Bli med og få alle fordelene du også!
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="tel"
              label="Mobilnummer"
              placeholder="Mobilnummer"
              error={(errors.phone?.message as string) || undefined}
              resetField={resetField}
              {...register('phone', {
                required: 'Vennligst oppgi telefon',
                maxLength: 100,
              })}
            />
            <Input
              type="email"
              label="E-postadresse"
              placeholder="E-postadresse"
              error={(errors.email?.message as string) || undefined}
              resetField={resetField}
              {...register('email', {
                required: 'Vennligst oppgi e-post',
                maxLength: 100,
              })}
            />
            <Select
              label="Butikk"
              placeholder="Velg butikk"
              defaultValue={store?.id ? `${store.id}` : undefined}
              error={(errors.store?.message as string) || undefined}
              resetField={resetField}
              {...register('store', {
                required:
                  'Vennligst oppgi hvilken butikk du ønsker å ha som hjemme butikk',
              })}
            >
              {Object.values(storesByRegion)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((region) => (
                  <optgroup key={region.iso} label={region.name}>
                    {region.stores.map(
                      (store) =>
                        store.id && (
                          <option key={store.id} value={store.id}>
                            {store.name}
                          </option>
                        )
                    )}
                  </optgroup>
                ))}
            </Select>

            <div className="mt-8">
              <Checkbox
                label={
                  <span>
                    JA, jeg gir samtykke til behandling av mine{' '}
                    <Link
                      href="/personvern"
                      className="underline"
                      onClick={(event) => {
                        setShowClubMemberForm(false);
                      }}
                    >
                      personopplysninger
                    </Link>{' '}
                    og vil at Fagmøbler sender meg relevant informasjon på
                    e-post.
                  </span>
                }
                error={(errors.terms?.message as string) || undefined}
                {...register('terms', {
                  required:
                    'Du må akseptere vilkårene for å kunne melde deg inn i kundeklubben',
                })}
              />
            </div>

            <Button
              type="submit"
              className={clsx('mt-8', 'w-full', 'justify-center')}
            >
              Meld deg inn
            </Button>
          </form>
        </>
      )}
    </section>
  );
}

export default ClubMemberForm;
