import {
  CategoryType,
  getPathFromCategory,
  getPathFromCategorySlug,
} from '@/lib/algolia/utils';
import { MaterializedInternalLink } from '@fagmobler/sanity';
import { GetStaticPropsContext } from 'next';
import slugify from 'slugify';

const whitespace = /\s+/g;

export const fagmoblerSlugify = (source: string) => {
  return slugify(source, '-').toLocaleLowerCase();
};

export const baseUrl = process.env.VERCEL_URL
  ? `https://${process.env.VERCEL_URL}`
  : `http://localhost:3000`;

export const getPathFromInternalLink = (
  internalLink: MaterializedInternalLink & { generatedUrl?: string },
  debug: any = {}
) => {
  if (internalLink.generatedUrl) {
    return internalLink.generatedUrl;
  }

  if (internalLink.algoliaProductCategorySlug) {
    return getPathFromCategorySlug(internalLink.algoliaProductCategorySlug);
  }

  // deprecated
  if (internalLink.algoliaProductCategory) {
    console.warn(
      'Do not use algoliaProductCategory, use algoliaProductCategorySlug instead',
      internalLink
    );
    return getPathFromCategory(internalLink.algoliaProductCategory);
  }

  if (internalLink.algoliaSerie) {
    return `/serie/${fagmoblerSlugify(internalLink.algoliaSerie)}`;
  }

  if (
    internalLink.page?.slug?.current &&
    internalLink.page?.category === 'inspirasjon'
  ) {
    return `/${internalLink.page.category}/${internalLink.page.slug.current}`;
  }

  if (internalLink.page?.slug?.current) {
    return `/${internalLink.page.slug?.current}`;
  }

  if (internalLink.productSKU) {
    return `/rom/${internalLink.productSKU}`;
  }

  if (internalLink.url) {
    if (internalLink.url[0] !== '/') {
      return `/${internalLink.url}`;
    } else {
      return internalLink.url;
    }
  }

  if (internalLink.externalUrl) {
    return internalLink.externalUrl;
  }

  if (internalLink.file?.asset?.url) {
    return internalLink.file?.asset?.url;
  }

  // console.info(`Could not create internal link`, { internalLink, debug });

  return undefined;
};

export const getServerUrlFromContext = (
  context: GetStaticPropsContext,
  categoryType: CategoryType
) => {
  const { params } = context;

  const slugs = params?.slugs ? [params.slugs].flat() : [];
  const baseUrl = process.env.VERCEL_URL
    ? `https://${process.env.VERCEL_URL}`
    : `http://localhost:3000`;

  let rootPath = 'rom';
  if (categoryType === 'articleGroup') rootPath = 'produkter';

  const slugsPath = slugs.join('/');
  const serverUrl = `${baseUrl}/${rootPath}/${slugsPath}`;

  return serverUrl;
};
