import clsx from 'clsx';

import { formatPrice } from '@fagmobler/ui';

import type { FagmoblerPublicOmniumProduct } from '@fagmobler/types';

export type PriceProps = {
  product?: FagmoblerPublicOmniumProduct;
  price?: number;
  isMemberPrice: boolean;
};

export function Price({ product, isMemberPrice, price }: PriceProps) {
  if (!product || !product?.price?.unitPrice) return null;  

  if (product.price.promotionName) {
    return (
      <>
        {product.price.promotionName === "Lavpris" ? (
          <p className="cap-xxs">Fast lavpris!</p>
        ) : (
          <>
            {isMemberPrice ? <p className="cap-xxs">Medlemspris</p> : <p className="cap-xxs">Tilbudspris</p>}
          </>
        )}
        <h4 className={clsx('hd-xl', 'text-primary-50', 'font-bold')}>
          {formatPrice(product.price.unitPrice)}
        </h4>
        {product.price.originalUnitPrice && product.price.originalUnitPrice != product.price.unitPrice && (
          <p className="cap-xxs">
            {isMemberPrice && `Ikke medlemspris ${formatPrice(product.price.originalUnitPrice)}`}
            {!isMemberPrice && product.price.originalUnitPrice && `Før ${formatPrice(product.price.originalUnitPrice)}`}
          </p>
        )}
      </>
    );
  }

  return (
    <p className="font-bold hd-xl">
      {formatPrice(product.price.unitPrice)}
    </p>
  );
}

export default Price;
