import clsx from 'clsx';

import type { FagmoblerOmniumCart } from '@fagmobler/types';
import { Button } from '@fagmobler/ui';

import { useNavigation } from '@/contexts/navigation';

import { useOpeningHours } from '@/components/store/StoreOpeningHours';
import type { OmniumStoreWithStock } from '@/components/store/useStoresWithStock';

export type CartStoreProps = {
  store?: OmniumStoreWithStock;
  stores: OmniumStoreWithStock[];
  cart: FagmoblerOmniumCart;
};

/**
 * CartStore component
 *
 * @returns {JSX}
 */
export function CartStore({ store, stores = [], cart }: CartStoreProps) {
  const { setShowStores } = useNavigation();
  const openingHours = useOpeningHours(store, true);
  // console.log('lineItems', cart.orderForm?.lineItems);
  // console.log('stock', store.stock);

  const itemsInCart =
    cart.orderForm?.lineItems?.reduce(
      (acc, curr) => acc + (curr.quantity || 0),
      0
    ) ?? 0;

  const itemsInStock =
    cart.orderForm?.lineItems?.reduce(
      (acc, curr) =>
        acc +
        Math.min(curr.quantity || 0, store?.stock[curr.code ?? '']?.stock || 0),
      0
    ) ?? 0;

  const itemsOnDisplay =
    cart.orderForm?.lineItems?.reduce(
      (acc, curr) =>
        acc +
        Math.min(
          curr.quantity || 0,
          store?.stock[curr.code ?? '']?.display || 0
        ),
      0
    ) ?? 0;

  const allCartItemsInStock = itemsInCart === itemsInStock;
  const allCartItemsOnDisplay = itemsInCart === itemsOnDisplay;

  const stock = {
    stock: allCartItemsInStock ? 1 : 0,
    display: allCartItemsOnDisplay ? 1 : 0,
  };

  if (!store) {
    return (
      <section>
        <header>
          <h4 className="hd-md">Velg butikk</h4>
        </header>
        <div className="flex justify-between items-center">
          <p className="body-sm text-neutral-70 mt-2">
            Velg mellom {stores.length} butikker i Norge
          </p>
          <Button onClick={() => setShowStores(true)}>Velg</Button>
        </div>
      </section>
    );
  }

  // if (compact) {
  //   return (
  //     <div>
  //       <StoreAddress store={store} />
  //       <StoreOpeningHours store={store} compact={false} />
  //       <Button
  //         variant="outline"
  //         className={clsx('w-full', 'mt-4', 'justify-center')}
  //         onClick={onStoreChange}
  //       >
  //         Velg annen butikk
  //       </Button>
  //     </div>
  //   );
  // }

  return (
    <section>
      <div className="flex justify-between items-center">
        <div>
          <header>
            <h4 className="hd-md">{store.name}</h4>
          </header>
          <p className="body-sm text-neutral-70 mt-2">
            {store.address?.streetName} {store.address?.streetNumber},{' '}
            {store.address?.zipcode} {store.address?.city}
          </p>
        </div>
        <Button variant="ghost" onClick={() => setShowStores(true)}>
          Endre
        </Button>
      </div>

      <div className={clsx('mt-5')}>
        <h3 className={clsx('hd-base', 'mb-2')}>Åpningstider</h3>
        {Object.entries(openingHours).map(([key, value]) => (
          <p
            key={key}
            className={clsx(
              'flex',
              'justify-between',
              'body-sm',
              'text-neutral-70'
            )}
          >
            <span>{key}</span>
            <span>{value}</span>
          </p>
        ))}
      </div>
    </section>
  );
}

export default CartStore;
