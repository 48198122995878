import algoliasearch, { SearchIndex } from 'algoliasearch';
import algoliasearchLite from 'algoliasearch/lite';
import { SerializeFunction } from 'sanity-algolia/dist/types';
import indexer from 'sanity-algolia';

type IndexMap = { [p: string]: { index: SearchIndex; projection?: string } };
export type Indexer = {
  webhookSync: (sanityClient: any, reqestBody: any) => Promise<void>;
};

export const ALGOLIA_APPLICATION_ID =
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID;
export const ALGOLIA_SEARCH_API_KEY =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY;

export const searchClient = algoliasearch(
  ALGOLIA_APPLICATION_ID || 'error',
  ALGOLIA_SEARCH_API_KEY || 'error'
);

export const searchClientLite = algoliasearchLite(
  ALGOLIA_APPLICATION_ID || 'error',
  ALGOLIA_SEARCH_API_KEY || 'error'
);

export const ALGOLIA_INDEX_NAME =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || 'try_fagmobler';

export const ALGOLIA_INDEX_NAME_CATEGORY_SLUGS =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || 'try_fagmobler_categorySlugs';

export const ALGOLIA_INDEX_NAME_ARTICLEGROUP_SLUGS =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME ||
  'try_fagmobler_articleGroupSlugs';

export const ALGOLIA_DEFAULT_PROPS = {
  searchClient: searchClientLite,
  indexName: ALGOLIA_INDEX_NAME,
};

export const getAlgoliaIndexer = (
  typeIndexMap: IndexMap,
  serializer: SerializeFunction
): Indexer => {
  return indexer(typeIndexMap, serializer);
};
