import { useQueryClient, useMutation } from 'react-query';
import type { FagmoblerOmniumCart } from '@fagmobler/types';
import type { OmniumCart, OmniumOrderLine } from '@fagmobler/omnium';

async function removeItemFromCart(orderLine: OmniumOrderLine) {
  const res = await fetch('/api/cart/items/remove', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      lineItemId: orderLine.lineItemId,
    }),
  });
  // console.log('res from removeItemFromCart', res);

  const data = (await res.json()) as FagmoblerOmniumCart;
  // console.log('data from removeItemFromCart', data);

  return data;
}

export function useRemoveItemFromCart() {
  const queryClient = useQueryClient();

  return useMutation<OmniumCart, FagmoblerOmniumCart, OmniumOrderLine>(
    (orderLine) => removeItemFromCart(orderLine),
    {
      onMutate: async (item) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['cart', item.code]);

        // Snapshot the previous value
        const previousItem = queryClient.getQueryData<OmniumCart>([
          'cart',
          item.code,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData(['cart', item.code], item);

        // Return a context with the previous and new
        return { previousItem, item };
      },
      onSuccess: (newCart) => {
        // ✅ update detail view directly
        if (newCart.id) {
          window.localStorage.setItem('cartId', newCart.id);
        }
        queryClient.setQueryData('cart', {
          ...newCart,
        });
      },
    }
  );
}
