import Image, { ImageProps } from 'next/image';
import {
  MaterializedImageWithPoints,
  ProductSKU,
  SanityKeyed,
} from '@fagmobler/sanity';
import ProductGridItem from '../product/GridItem';
import { FagmoblerAlgoliaProduct } from '@fagmobler/types';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

type ImageWithPointsProps = {
  imageWithPoints: MaterializedImageWithPoints;
  className?: string;
  sizes: ImageProps['sizes'];
};

// TODO import this from types
type TPoint = SanityKeyed<{
  _type: 'spot';
  x?: number;
  y?: number;
  productSKU?: ProductSKU;
  product?: FagmoblerAlgoliaProduct;
}>;

const Item = ({ point }: { point: TPoint }) => {
  const product = point.product;

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  useEffect(() => {
    if (typeof window !== 'undefined' && document.body) {
      setPortalElement(document.body);
    }
  }, []);

  if (!point || !point.x || !point.y) return null;

  return (
    <Popover
      className="absolute"
      style={{
        top: `${point.y}%`,
        left: `${point.x}%`,
      }}
    >
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              'absolute rounded-full h-8 w-8 bg-neutral-0',
              'shadow-lg shadow-neutral-70 -translate-x-1/2 -translate-y-1/2',
              'flex items-center justify-center align-center text-lg',
              'opacity-70 hover:opacity-100',
              {
                'opacity-100!': open,
              }
            )}
            ref={setReferenceElement}
          >
            +
          </Popover.Button>
          {portalElement &&
            createPortal(
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
                className={clsx('absolute', 'z-10')}
              >
                <Popover.Panel
                  ref={setPopperElement}
                  unmount={false}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {product && (
                    <div className="bg-neutral-0 shadow-2xl shadow-black w-52 p-4">
                      <ProductGridItem product={product} imageSizes="50" showImage={false} />
                    </div>
                  )}
                </Popover.Panel>
              </Transition>,
              portalElement
            )}
        </>
      )}
    </Popover>
  );
};

export const ImageWithPoints = ({
  imageWithPoints,
  className,
  sizes,
}: ImageWithPointsProps) => {
  if (!imageWithPoints?.image) return null;
  const src =
    imageWithPoints?.image.croppedImageUrl || imageWithPoints?.image.url;
  const width = imageWithPoints?.image.width;
  const lqip = imageWithPoints?.image?.lqip;
  const height = imageWithPoints?.image.height;
  const points = imageWithPoints?.points;

  if (!src || !width || !height) return null;
  return (
    <div className={clsx('relative', 'w-full', className)}>
      <Image
        src={src}
        height={height}
        width={width}
        alt="test"
        className="w-full max-w-none overflow:hidden"
        sizes={sizes}
        placeholder={lqip ? 'blur' : undefined}
        blurDataURL={lqip || undefined}
      />
      {points?.map((point) => {
        const { x, y, productSKU } = point;

        if (!x || !productSKU || !y) return null;
        return <Item point={point} key={point._key} />;
      })}
    </div>
  );
};
