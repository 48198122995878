import clsx from 'clsx';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Button } from '@fagmobler/ui';

export const HeaderSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { prefetch, replace, push, pathname, asPath, events } = router;
  const q = (router.query.q as string) || ('' as string);

  const [inputValue, setInputValue] = useState(q);
  const [startPath, setStartPath] = useState<string | null>(null);
  const cancelRef = useRef<AbortController | null>(null);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!url.startsWith('/sok')) {
        setInputValue('');
      }
      if (url.startsWith('/sok')) {
        inputRef.current?.focus();
      }
    };

    events?.on('routeChangeComplete', handleRouteChange);
    return () => {
      events?.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  useEffect(() => {
    const handleMouseenter = () => {
      prefetch('/sok');
    };
    const el = inputRef.current;
    el?.addEventListener('mouseenter', handleMouseenter);

    return () => {
      el?.removeEventListener('mouseenter', handleMouseenter);
    };
  }, [prefetch]);

  const debouncedReplace = useCallback(
    (url: string) => {
      if (cancelRef.current) {
        cancelRef.current.abort();
      }
      cancelRef.current = new AbortController();

      const timeoutId = setTimeout(() => {
        if (q) {
          replace(url, undefined, {
            shallow: true,
          });
        } else {
          push(url, undefined, {
            shallow: true,
          });
        }
      }, 700);

      return () => {
        clearTimeout(timeoutId);
        if (cancelRef.current) {
          cancelRef.current.abort();
        }
      };
    },
    [push, q, replace]
  );

  const handleFocus = () => {
    prefetch('/sok');
    // if (asPath && inputValue === '' && !q && !asPath.startsWith('/sok')) {
    //   setStartPath(asPath);
    // }
  };

  const cancelSearch = useCallback(() => {
    if (cancelRef.current) {
      cancelRef.current.abort();
    }
    setInputValue('');
  }, []);

  const handleCloseSearch = useCallback(() => {
    router.back();
  }, [router]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || '';
    if (!value) {
      router.replace(startPath || '/');
    }
    setInputValue(value);
  };

  useEffect(() => {
    if (inputValue === '') {
      return;
    }
    if (q === inputValue) {
      return;
    }

    // if (inputValue === '') {
    //   handleCloseSearch();
    //   return;
    // }

    if (inputValue.length < 3) {
      return;
    }

    const url = `/sok?q=${encodeURIComponent(inputValue)}`;
    const cancelSearch = debouncedReplace(url);

    return () => {
      cancelSearch();
    };
  }, [inputValue, q, handleCloseSearch, debouncedReplace, asPath]);

  return (
    <div className="flex lg:flex-shrink-0 lg:flex-grow lg:mx-5 basis-full order-last lg:order-none lg:basis-0 gap-4">
      <input
        value={inputValue && typeof inputValue == 'string' ? inputValue : q}
        ref={inputRef}
        type="search"
        placeholder="Søk etter produkter, kategorier, varemerker..."
        className={clsx(
          'flex bg-neutral-70 outline-none search-cancel:hidden',
          'flex-shrink-0 flex-grow',
          'focus-within:outline focus-within:outline-neutral-60',
          'p-2 text-base rounded-full pl-4'
        )}
        onFocus={handleFocus}
        onChange={handleInputChange}
      />
      {router.pathname === '/sok' && (
        <Button variant="rounded" onClick={handleCloseSearch}>
          <span className="flex items-center gap-2 leading-none">
            Lukk søk
            <XMarkIcon className="w-5 h-5" />
          </span>
        </Button>
      )}
    </div>
  );
};
