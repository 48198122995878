import type { TNavigationItem } from '@/lib/sanity/queries';
import { NavigationDrawer } from '../NavigationDrawer';
import { useState } from 'react';

export function MobileNavigation() {
  const [selectedMainItem, setSelectedMainItem] =
    useState<TNavigationItem | null>(null);

  const onClose = (item: TNavigationItem | null) => {
    setSelectedMainItem(item);
  };

  return (
    <div className="lg:hidden print:hidden">
      <div className="absolute block xl:hidden">
        <NavigationDrawer
          selectedMainItem={selectedMainItem}
          onClose={(): void => onClose(null)}
        />
      </div>
    </div>
  );
}

export default MobileNavigation;
