import { useNavigation } from '@/contexts/navigation';
import {
  Button,
  DefaultModal,
  Input,
  Loading,
  LocationPinIcon,
} from '@fagmobler/ui';
import { Popover } from '@headlessui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { StoreOption, useStoresByRegion, useStoresInRegion } from '../store';
import { useStores } from '@/components/store';
import { useCart } from '../cart';
import { setCookie, getCookie } from 'cookies-next';
import { OmniumStore } from '@fagmobler/omnium';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

type DeliveryStore = {
  butikkdata_nr: string;
  drive_distance: number;
};

const PickStore = ({
  deliveryStores,
  onSelect,
  zip,
  stores,
}: {
  deliveryStores: DeliveryStore[];
  onSelect: (storeId: string) => void;
  zip: string;
  stores: OmniumStore[];
}) => {
  return (
    <>
      <h3 className="text-md mt-6 mb-4">
        {deliveryStores.length} butikker leverer til postnummer {zip}
      </h3>
      {deliveryStores.map((deliveryStore) => {
        const store = stores.find(
          (store) => store.id === deliveryStore.butikkdata_nr
        );
        if (!store) return null;
        return (
          <StoreOption
            key={store.id}
            store={store}
            isSelected={false}
            onSelectStore={() => onSelect(store.id!)}
            // distance={deliveryStore.drive_distance}
          />
        );
      })}
    </>
  );
};

export const ZipCode = ({ className }: { className?: string }) => {
  const { zipCode, setZipCode } = useNavigation();
  const [zip, setZip] = useState(zipCode);
  const [deliveryStores, setDeliveryStores] = useState<DeliveryStore[] | null>(
    null
  );
  const { setStore } = useCart();
  const { current, stores } = useStores();
  const path = usePathname();
  const storesByRegion = useStoresByRegion(stores.data || []);
  const storesInRegion = useStoresInRegion(
    storesByRegion,
    current.data?.region
  );
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [hasClicked, setHasClicked] = useState(false);

  const handleMouseUp = () => {
    setHasClicked(true);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const fallbackZipCode = storesInRegion?.[0]?.address?.zipcode || '';

  const loading =
    stores.isLoading ||
    isLoading ||
    current.isLoading ||
    storesByRegion.isLoading;

  // Use this to set the zip code to the fallback zip code from vercel
  // useEffect(() => {
  //   if (fallbackZipCode) {
  //     setZip(fallbackZipCode);
  //   }
  // }, [fallbackZipCode]);

  useEffect(() => {
    const cookieZip = getCookie('zip_code');
    if (typeof cookieZip === 'string') {
      setZipCode(cookieZip);
    }
    // We only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = useCallback(
    async (storeId: string) => {
      setCookie('zip_code', zip, {
        maxAge: 1000 * 60 * 60 * 24 * 365, // one year
      });
      await setStore.mutateAsync(storeId);
      await current.refetch();
      setZipCode(zip);
      setShowModal(false);
    },
    [current, setStore, setZipCode, zip]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent, onClose: () => void) => {
      setLoading(true);
      setShowModal(true);
      fetch(`/api/stores/getStoresFromZip?zip=${zip || zipCode}`).then(
        (result) => {
          result
            .json()
            .then((data) => {
              const stores = data.deliveryStores;
              setDeliveryStores(stores);
              if (stores.length === 1) {
                setShowModal(false);
                handleSelect(stores[0].butikkdata_nr);
              } else {
                setShowError(true);
                // setShowModal(true);
              }

              setLoading(false);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      );
      e.preventDefault();
      e.stopPropagation();
    },
    [handleSelect, zip, zipCode]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 4) {
      return;
    }

    setShowError(false);
    setZip(e.target.value);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOptOut = useCallback(async () => {
    if (!zipCode) {
      setZipCode(fallbackZipCode);
      if (storesInRegion?.length > 0) {
        await setStore.mutateAsync(storesInRegion[0].id);
      }
      current.refetch();
    }
    setShowModal(false);
  }, [zipCode, setZipCode, fallbackZipCode, storesInRegion, current, setStore]);

  useEffect(() => {
    if (!zipCode && !zip) {
      inputRef.current?.focus();
    }
  }, [zip, zipCode, inputRef]);

  if (loading) {
    return null;
  }

  if ((!zipCode || showModal) && hasClicked) {
    return (
      <DefaultModal
        isOpen={true}
        onClose={handleOptOut}
        title="Hvor handler du fra?"
      >
        {loading && <Loading />}
        {deliveryStores && deliveryStores.length > 1 && stores.data && zip ? (
          <PickStore
            deliveryStores={deliveryStores}
            zip={zip}
            onSelect={handleSelect}
            stores={stores.data}
          />
        ) : (
          <form
            onSubmit={(e) => handleSubmit(e, handleClose)}
            className="flex flex-col gap-4 mt-4"
          >
            <p className="text-neutral-70 body-sm">
              For å vise deg varer som er tilgjengelig i ditt område, må vi vite
              ditt postnummer. Du kan gå videre uten å fylle inn postnummer.
            </p>
            <Input
              type="number"
              name="zipcode"
              label="Postnummer"
              value={zip}
              onChange={handleChange}
              onReset={() => setZip('')}
              autoFocus
              autoComplete="postal-code"
              icon={<LocationPinIcon className="h-6 w-6" />}
              placeholder={`Eksempel: ${fallbackZipCode || '1234'}`}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(e, close);
                }
              }}
              onClick={(e) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
              onFocus={(e) => {
                const target = e.target as HTMLInputElement;
                target.select();
              }}
            />
            {showError && (
              <p className="text-primary-50 text-sm">
                Vi fant ingen butikker som leverer til postnummer {zip}
              </p>
            )}

            <Button
              type="submit"
              size="lg"
              className="justify-center"
              disabled={!zip || zip.length !== 4 || showError}
            >
              Bekreft
            </Button>
            <button
              className="text-center underline underline-offset-4 text-sm"
              onClick={handleOptOut}
            >
              Jeg vil ikke oppgi postnummeret mitt
            </button>
          </form>
        )}
      </DefaultModal>
    );
  }

  if (!zipCode) {
    return null;
  }

  if (path.startsWith('/sok') || path === '/') {
    return null;
  }

  return (
    <div className={clsx('relative ml-auto', className)}>
      <Popover>
        {({ close }) => (
          <>
            <Popover.Button
              className={clsx('text-neutral-70 cap-xs', {
                hidden: path.startsWith('/sok') || path === '/',
              })}
            >
              Du handler fra{' '}
              <span className="underline underline-offset-2">
                {zipCode} <LocationPinIcon className="inline h-4 w-4" />
              </span>
            </Popover.Button>
            <Popover.Panel className="absolute w-80 right-0 top-8 z-50">
              <form
                onSubmit={(e) => handleSubmit(e, close)}
                className="shadow-xl bg-neutral-0 p-6 rounded-lg"
              >
                <h3 className="border-b border-neutral-40 pb-4 mb-4 hd-md">
                  Legg inn ditt postnummer
                </h3>
                <p className="text-sm text-neutral-70 mb-6">
                  Vi bruker postnummeret til å vise deg varer som er
                  tilgjengelig i ditt område.
                </p>
                <Input
                  value={zip}
                  autoFocus
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e, close);
                    }
                  }}
                  onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.select();
                  }}
                  onFocus={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.select();
                  }}
                  resetField={() => {
                    setZip('');
                  }}
                  label="Postnummer"
                  name="zipcode"
                  placeholder="Eksempel: 1234"
                  autoComplete="postal-code"
                  icon={<LocationPinIcon className="h-5 w-5" />}
                />
                <Button
                  className="w-full justify-center"
                  size="lg"
                  type="submit"
                >
                  Endre
                </Button>
              </form>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};
