import { useNavigation } from '@/contexts/navigation';
import Link from 'next/link';
import NextImage from 'next/image';
import clsx from 'clsx';

type MegaMenuAsideProps = {
  onClose(): void;
};

export const MegaMenuAside = ({ onClose }: MegaMenuAsideProps) => {
  const { menuInspiration = [], menuBanners = [] } = useNavigation();
  return (
    <div className="relative flex flex-col p-10 bg-neutral-40 max-w-[590px]">
      <div className="flex justify-between mb-4">
        <h2 className="text-md font-500 font-heading text-neutral-80">
          Inspirasjon
        </h2>
        <Link
          href="/inspirasjon"
          onClick={onClose}
          className="text-sm text-neutral-80 underline"
        >
          Se alle
        </Link>
      </div>
      <ul className="grid grid-cols-3 gap-3 mb-12">
        {menuInspiration.map((item) => {
          if (!item.image.croppedImageUrl) return;
          return (
            <li key={`${item._key}${item.title}`}>
              <Link
                href={item.path}
                onClick={onClose}
                className="group flex flex-col gap-2"
              >
                <figure className="relative w-full h-[102px] overflow-hidden">
                  <NextImage
                    src={item.image.croppedImageUrl}
                    alt={item.image.alt || item.title}
                    style={{ objectFit: 'cover' }}
                    className="object-contain w-auto h-auto"
                    fill
                  />
                </figure>
                <h3 className="text-neutral-80 text-sm font-normal group-hover:underline">
                  {item.title}
                </h3>
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className="grid grid-cols-2 gap-6">
        {menuBanners.map((item) => {
          return (
            <li key={`${item._key}${item.title}`}>
              <Link
                href={item.path.startsWith('/') ? item.path : `/${item.path}`}
                onClick={onClose}
                className="flex flex-col gap-2 group"
              >
                <figure
                  className={clsx(
                    'relative w-full overflow-hidden aspect-[254/238]',
                    item.backgroundColor
                  )}
                >
                  {item.image?.croppedImageUrl && (
                    <NextImage
                      src={item.image.croppedImageUrl}
                      alt={item.image.alt || item.title || ''}
                      style={{ objectFit: 'cover' }}
                      className="object-contain w-auto h-auto"
                      fill
                    />
                  )}
                  {item.title && (
                    <figcaption className="absolute inset-0 p-2 text-center flex items-center justify-center">
                      <div
                        className={clsx('absolute inset-0', {
                          'bg-neutral-80 opacity-30':
                            item.image?.croppedImageUrl,
                        })}
                      />
                      <h3 className="hd-lg z-20 group-hover:underline">
                        {item.title}
                      </h3>
                    </figcaption>
                  )}
                </figure>
                <h3 className="text-neutral-80 text-sm font-normal group-hover:underline">
                  {item.linkLabel}
                </h3>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
