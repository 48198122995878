import { useMutation, useQueryClient } from 'react-query';
import type { FagmoblerOmniumCart } from '@fagmobler/types';

async function updateCart(body: {
  isMember: boolean;
  ignorePromotions?: boolean;
}): Promise<FagmoblerOmniumCart> {
  const res = await fetch(`/api/cart`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  const data = (await res.json()) as FagmoblerOmniumCart;
  return data;
}

export function useUpdateCart() {
  const queryClient = useQueryClient();

  return useMutation<
    FagmoblerOmniumCart,
    FagmoblerOmniumCart,
    { isMember: boolean; ignorePromotions?: boolean }
  >((data) => updateCart(data), {
    onSuccess: (newCart) => {
      queryClient.setQueryData('cart', {
        ...newCart,
      });
    },
    onMutate: async (updates) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['cart']);

      // Snapshot the previous value
      const cart = queryClient.getQueryData<FagmoblerOmniumCart>(['cart']);

      const ignorePromotions =
        typeof updates.ignorePromotions === 'undefined'
          ? !updates.isMember
          : updates.ignorePromotions;
      // Optimistically update to the new value
      queryClient.setQueryData<FagmoblerOmniumCart>(['cart'], (old) => ({
        ...old,
        ignorePromotions,
      }));

      return { cart };
    },
  });
}
