import clsx from 'clsx';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

import { Button, Logo } from '@fagmobler/ui';
import { OmniumStore } from '@fagmobler/omnium';

import { useNavigation } from '@/contexts/navigation';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';

import { useStores, useOpeningHours } from '@/components/store';
import InstagramIcon from './icons/instagram';
import FacebookIcon from './icons/facebook';
import PintrestLogo from './icons/pintrest';
import KlarnaLogo from './icons/klarna';
import Link from 'next/link';
import { getStorePath } from '@/lib/omnium/getStoreUrl';

const Footer = () => {
  const { current } = useStores();
  const store = current?.data?.store;
  const { footer, setShowStores } = useNavigation();
  const openingHours = useOpeningHours(store as OmniumStore, true);
  const storePath = store ? getStorePath(store) : null;
  return (
    <footer className="bg-neutral-80 text-neutral-0 py-20 mt-20 print:text-neutral-80 print:bg-[transparent] print:mt-7 print:py-0 print:pt-4 print:border-t print:bottom-[1cm] print:w-[calc(100vw-2cm)]">
      <div className="container grid grid-1 md:grid-cols-2 lg:grid-cols-4 print:flex print:justify-between print:px-0 print:max-w-full">
        <div>
          <Logo className="h-10 print:h-3" />
          <p className="body-md mt-4 print:mt-0 print:body-xs">
            Møbler i høy kvalitet <br /> – til gode priser!
          </p>
          <div className="flex gap-4 my-10 md:my-20 print:hidden">
            <a href="https://www.instagram.com/fagmobler/" title="Instagram">
              <InstagramIcon className="h-6 w-6" />
            </a>
            <a href="https://www.facebook.com/Fagmobler" title="Facebook">
              <FacebookIcon className="h-6 w-6" />
            </a>
            <a href="https://www.pinterest.com/fagmobler/" title="Pintrest">
              <PintrestLogo className="h-6 w-6" />
            </a>
            <Link legacyBehavior href="/betal-med-klarna">
              <a title="betal med Klarna">
                <KlarnaLogo className="h-6" />
              </a>
            </Link>
          </div>
        </div>

        {footer?.map((block) => (
          <div key={block._key} className="print:hidden">
            <h3 className="hd-md mb-4">{block.title}</h3>
            <ul className="mb-10">
              {block.children?.map((item) => (
                <li key={item._key}>
                  <Link
                    className="block py-2 hover:underline"
                    href={
                      (item.internalLink &&
                        getPathFromInternalLink(item.internalLink, item)) ||
                      '/'
                    }
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div>
          <h3 className="hd-md mb-4 print:hidden">Min butikk</h3>
          {!store ? (
            <Button
              variant="ghost"
              onClick={() => setShowStores(true)}
              className="print:hidden"
            >
              Velg butikk <ArrowRightIcon className="w-3 h-3 ml-2" />
            </Button>
          ) : (
            <div className="print:body-xs print:flex print:justify-between">
              <div className="print:mr-10">
                <h4 className="print:cap-sm print:mb-2">
                  <Link
                    className="hover:underline"
                    href={storePath || '/min-butikk'}
                  >
                    {store.name}
                  </Link>
                </h4>
                <a href={`tel:+47 ${store.phoneNumber}`} className="block">
                  +47 {store.phoneNumber}
                </a>
                <a
                  href={`mailto:${store.email}`}
                  className="block underline underline-offset-4"
                >
                  {store.email}
                </a>
              </div>
              <div className="mt-10 print:mt-0">
                <div
                  className={clsx(
                    'mt-5',
                    'mb-2',
                    'print:body-xs',
                    'print:mt-0',
                    'print:mb-0'
                  )}
                >
                  <h3 className={clsx('mb-2 print:cap-sm')}>Åpningstider</h3>
                  {Object.entries(openingHours).map(([key, value]) => (
                    <p
                      key={key}
                      className={clsx(
                        'flex',
                        'justify-between',
                        'print:body-xs'
                      )}
                    >
                      <span className="print:mr-5">{key}</span>
                      <span className="">
                        {value === 'stengt' ? (
                          <span className="text-primary-50">{value}</span>
                        ) : (
                          value
                        )}
                      </span>
                    </p>
                  ))}
                </div>
              </div>

              <div className="mt-4 print:hidden">
                <Button
                  variant="ghost-dark"
                  onClick={() => setShowStores(true)}
                >
                  Endre butikk
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
