import React from 'react';

function KlarnaLogo({ className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 24"
      className={className}
    >
      <path
        fill="#FFB3C7"
        d="M37.063.75H3.937C1.763.75 0 2.53 0 4.725v15.05c0 2.195 1.763 3.975 3.937 3.975h33.126c2.174 0 3.937-1.78 3.937-3.975V4.725C41 2.53 39.237.75 37.063.75z"
      ></path>
      <path
        fill="#000"
        d="M37.168 14.225a.98.98 0 00-.97.99.98.98 0 00.97.989.98.98 0 00.972-.99.98.98 0 00-.972-.99zm-3.195-.765c0-.749-.628-1.355-1.402-1.355-.775 0-1.402.606-1.402 1.354 0 .749.627 1.355 1.402 1.355.774 0 1.402-.606 1.402-1.354zm.005-2.634h1.547v5.267h-1.547v-.337a2.678 2.678 0 01-1.534.482c-1.506 0-2.727-1.244-2.727-2.779 0-1.534 1.22-2.778 2.727-2.778.57 0 1.097.178 1.534.482v-.337zm-12.384.686v-.686H20.01v5.267h1.588v-2.46c0-.829.882-1.275 1.495-1.275l.018.001v-1.532c-.629 0-1.207.274-1.517.685zm-3.946 1.947c0-.748-.628-1.354-1.402-1.354-.775 0-1.403.606-1.403 1.354 0 .749.628 1.356 1.402 1.356.775 0 1.403-.607 1.403-1.355zm.005-2.633H19.2v5.267h-1.547v-.337a2.678 2.678 0 01-1.534.482c-1.506 0-2.727-1.244-2.727-2.778 0-1.535 1.22-2.78 2.727-2.78.569 0 1.097.18 1.534.483v-.337zm9.314-.141c-.618 0-1.203.195-1.594.735v-.593h-1.541v5.266h1.56v-2.768c0-.8.527-1.193 1.161-1.193.68 0 1.071.414 1.071 1.182v2.779h1.546v-3.35c0-1.225-.956-2.058-2.203-2.058zm-15.83 5.408h1.62V8.479h-1.62v7.614zm-7.115.002h1.716V8.478H4.022v7.617zm6-7.617a6.116 6.116 0 01-1.755 4.323l2.37 3.294H8.52l-2.576-3.58.665-.507a4.402 4.402 0 001.735-3.53h1.678z"
      ></path>
    </svg>
  );
}

export default KlarnaLogo;
