import { StickerProps } from '@fagmobler/ui/src/components';

const offerTags = [
  'BLACK WEEK',
  'nyhet',
  'KUPP!',
  'Medlemstilbud',
  'medlemstilbud',
  'outlet',
  'salg',
  'kupp',
  'Lavpris',
  'Fast lavpris',
  'BLACK WEEK MEDLEM',
  'BLACK WEEK MEDLEM 1',
  'BLACK WEEK MEDLEM 2',
];

const infoTags = ['bestselger', 'prisgaranti', 'kampanje'];

const productTags: string[] = [...offerTags, ...infoTags];

export const getTagsForDisplay = (tags?: string[], tagLimit = 4) => {
  if (!tags || tags.length === 0) return [];

  if (tags.includes('BLACK WEEK MEDLEM')) {
    if (tagLimit <= 2) {
      tags = [];
    } else {
      tags = tags.filter((tag) => tag !== 'BLACK WEEK MEDLEM');
    }
    // tags = [];
    tags.push('BLACK WEEK MEDLEM 1');
    tags.push('BLACK WEEK MEDLEM 2');
  }

  const offerTagsInProduct = tags.filter((tag) => offerTags.includes(tag));
  const infoTagsInProduct = tags.filter((tag) => infoTags.includes(tag));

  return offerTagsInProduct
    .reduce((acc: string[], value, index) => {
      acc.push(value);

      if (infoTagsInProduct[index] !== undefined) {
        acc.push(infoTagsInProduct[index]);
      }

      return acc;
    }, [])
    .slice(0, tagLimit)
    .filter((tag) => productTags.includes(tag))
    .map((tag) => ({
      label: rewriteTagName(tag),
      variant: getTagVariant(tag),
    }));
};

function getTagVariant(tag: string): StickerProps['variant'] {
  if (
    tag === 'KUPP!' ||
    tag === 'salg' ||
    tag === 'kupp' ||
    tag === 'BLACK WEEK MEDLEM 2'
  )
    return 'primaryDark';
  if (tag === 'nyhet') return 'secondaryDark';
  if (tag === 'bestselger') return 'boxed';
  return 'neutralDark';
}

function rewriteTagName(tag: string): string {
  if (tag === 'nyhet') return 'NYHET';
  if (tag === 'outlet') return 'Outlet';
  if (tag === 'kampanje') return 'Medlemstilbud';
  if (tag === 'BLACK WEEK') return 'BLACK WEEK';
  if (tag === 'KUPP!') return 'Medlemskupp';
  if (tag === 'salg') return 'Salg';
  if (tag === 'kupp') return 'Kupp!';
  if (tag === 'Medlemstilbud') return 'Medlemspris';
  if (tag === 'prisgaranti') return 'PRISGARANTI';
  if (tag === 'Lavpris') return 'FAST LAVPRIS!';
  if (tag === 'Fast lavpris') return 'FAST LAVPRIS!';
  if (tag === 'BLACK WEEK MEDLEM 1') return 'BLACK WEEK';
  if (tag === 'BLACK WEEK MEDLEM 2') return 'Medlem';
  return tag;
}
