import { useEffect, useState, type MouseEvent } from 'react';
import Link from 'next/link';
import { MaterializedProductCollectionBlock } from '@fagmobler/sanity';
import { ProductCollectionCard, ProductListGridItem } from '@fagmobler/ui';
import { getCanonicalPath } from '@/lib/algolia/getCanonicalPath';
import { useStores } from '../store';
import { FagmoblerAlgoliaProduct } from '@/../../packages/types';
import Image from 'next/image';
import { missingImageUrls } from '@/lib/utils/missingImageUrls';
import { BlockWrapper } from './BlockWrapper';
import {
  DataLayerEcommerceProduct,
  EcommerceObject,
  trackEvent,
} from '@/lib/analytics';
import { useRouter } from 'next/router';
import { getStorePath } from '@/lib/omnium/getStoreUrl';
import { getTagsForDisplay } from '@/lib/algolia/tags';
import { populateBlockWithProducts } from '@/components/contentBlocks/ContentBlocksRenderer';

export const RenderProductCollection = (
  value: MaterializedProductCollectionBlock | any
) => {
  const { current: currentStoreQuery } = useStores();
  const [products, setProducts] = useState(value.products);
  const { current } = useStores();
  const currentStore = current.data?.store || undefined;
  const router = useRouter();

  useEffect(() => {
    if (!value.products) {
      populateBlockWithProducts(value).then(setProducts);
    }
  }, [value]);

  const item_list_name = (value.title as string) || 'Produktliste';

  const handleOnView = () => {
    const items: DataLayerEcommerceProduct[] = value.products?.map(
      (product: FagmoblerAlgoliaProduct) => ({
        item_id: product.objectID,
        price: product.price,
        quantity: 1,
      })
    );

    const eCommerceObject: EcommerceObject = {
      item_list_name,
      items,
    };
    trackEvent('view_item_list', eCommerceObject, currentStore);
  };

  const handleProductClick = (product: FagmoblerAlgoliaProduct) => {
    trackEvent(
      'select_item',
      {
        item_list_name,
        items: [
          {
            item_id: product.objectID,
            price: product.price,
            quantity: 1,
          },
        ],
      },
      currentStore
    );
  };

  const store = currentStoreQuery.data?.store;

  const handleStoreClick = (event: MouseEvent) => {
    if (!store) return;

    const storeUrl = getStorePath(store);
    if (storeUrl) {
      router.push(storeUrl);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <BlockWrapper onView={handleOnView}>
      <ProductCollectionCard {...value} createUrl={getCanonicalPath}>
        {products?.map((product: FagmoblerAlgoliaProduct) => {
          const href = getCanonicalPath(product);
          const hasImage =
            product.image && !missingImageUrls.includes(product.image);

          if (!href) return null;
          return (
            <Link
              href={href}
              key={product.objectID}
              className="block"
              onClick={() => handleProductClick(product)}
            >
              <ProductListGridItem
                product={product}
                tags={getTagsForDisplay(product.tags, 2)}
                image={
                  hasImage && (
                    <Image
                      className="object-contain scale-75 sm:scale-100"
                      src={product.image!}
                      sizes="(max-width: 640px) 50vw, (max-width: 1024px) 164px, (max-width: 1280px) 166px, 211px"
                      fill
                      alt={
                        product.posterName
                          ? `$Bilde av ${product.posterName} (${product.posterDescription})`
                          : 'Bilde av produkt'
                      }
                    />
                  )
                }
                store={store}
                onStoreClick={handleStoreClick}
              />
            </Link>
          );
        })}
      </ProductCollectionCard>
    </BlockWrapper>
  );
};
