export function PurchaseTerms() {
  return (
    <section>
      <header>
        <h2>Hello From Purchase Terms</h2>
      </header>
    </section>
  );
}

export default PurchaseTerms;
