import {
  MaterializedImage,
  MaterializedImageWithCrop,
} from '@fagmobler/sanity';
import { PhotoIcon } from '@heroicons/react/20/solid';
import NextImage, { ImageProps } from 'next/image';
import clsx from 'clsx';
import { getSanityImageUrl } from '@/lib/sanity/sanityImage';
import { useEffect, useState } from 'react';
// Largest possible image in the container
// Tailwind default breakpoints
// None	    width:  100%;
// sm     (640px) 	max-width: 640px;
// md     (768px) 	max-width: 768px;
// lg     (1024px)  max-width: 1024px;
// xl     (1280px)  max-width: 1280px;
// 2xl    (1536px)  max-width: 1536px;
export const defaultSizes = `(max-width: 1200px) 100vw, 768px`;

export type ImageWithCaptionProps = ImageProps & {
  image?: MaterializedImageWithCrop | MaterializedImage | null;
  forceHeight?: boolean;
};

export const urlIs404Image = (url: string) => {
  return (
    url ===
    'https://imagedelivery.net/LfHarEEHw85fo7v7-lUDxQ/d5aea059-fbb4-4c8b-894d-eefa4d32d700/list'
  );
};

export const NoImage = ({
  text = 'Bilde mangler',
  width,
  height,
  className = '',
}: {
  text?: string;
  height?: number;
  width?: number;
  className?: ImageProps['className'];
}) => {
  const showText = (!width && !height) || (width && width > 100);
  return (
    <div className={`w-full h-full  text-neutral-50 ${className}`}>
      <div className="flex items-center justify-center h-full w-full flex-col">
        <PhotoIcon
          className={clsx('block', {
            'h-2/4 w-2/4': !width || width >= 100,
            'h-full w-full': width && width < 100,
          })}
        />
        {showText && text}
      </div>
    </div>
  );
};

export const ImageWithCaption = (props: ImageWithCaptionProps) => {
  const {
    width,
    height,
    src,
    image,
    className,
    alt,
    forceHeight = false,
  } = props;
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (image) {
        const url = await getSanityImageUrl(image);
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [image]);

  if ((imageUrl && !urlIs404Image(imageUrl)) || src) {
    return (
      <figure className="block relative w-full h-full overflow-hidden">
        <NextImage
          alt={alt}
          className="object-cover"
          width={typeof width === 'number' ? width : 0}
          height={typeof height === 'number' ? height : 0}
          src={imageUrl || src}
          sizes={defaultSizes}
          style={forceHeight ? { height: `${height}px` } : {}}
        />
        {props.image?.caption && (
          <figcaption className="cap-xs md:cap-base w-full mt-2">
            {props.image?.caption}
          </figcaption>
        )}
      </figure>
    );
  }
  return (
    <NoImage
      width={typeof width === 'number' ? width : undefined}
      height={typeof height === 'number' ? height : undefined}
      className={className}
    />
  );
};

export default ImageWithCaption;
