import { useQueryClient, useMutation } from 'react-query';
import { emptyCart } from './useCart';
import type { OmniumCart } from '@fagmobler/omnium';

async function deleteCart() {
  const res = await fetch(`/api/cart`, {
    method: 'DELETE',
  });

  if (res.status !== 204) {
    console.error('could not delete cart', res);
  }

  return emptyCart;
}

export function useDeleteCart() {
  const queryClient = useQueryClient();

  return useMutation<OmniumCart>(() => deleteCart(), {
    // onMutate: () => {

    // },
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.setQueryData('cart', { id: null });
    },
  });
}
