import { Suspense } from 'react';
import dynamic from 'next/dynamic';

import { DrawerModal } from '@fagmobler/ui';

import { useNavigation } from '@/contexts/navigation';

const StoreLocator = dynamic(() => import('@/components/store/StoreLocator'), {
  ssr: false,
});

export function StoreLocatorDrawer() {
  const { showStores, setShowStores } = useNavigation();

  return (
    <DrawerModal
      isOpen={showStores}
      onClose={() => setShowStores(false)}
      title="Velg butikk"
      unmount={true}
    >
      <Suspense fallback={`Loading...`}>
        <StoreLocator />
      </Suspense>
    </DrawerModal>
  );
}

export default StoreLocatorDrawer;
