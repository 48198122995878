import type {
  Breadcrumb,
  Breadcrumbs,
  FagmoblerAlgoliaProduct,
  FagmoblerOmniumProduct,
} from '@fagmobler/types';
import { fagmoblerSlugify } from '@/lib/utils/getUrls';
import { searchClient } from './searchClient';

export type CategoryType = 'articleGroup' | 'categories';

export const getPathFromCategory = (
  algoliaProductCategory: string,
  categoryRoot = 'rom'
) => {
  console.warn('getPathFromCategory is deprecated');
  if (!algoliaProductCategory || typeof algoliaProductCategory !== 'string')
    return null;
  return `/${categoryRoot}/${algoliaProductCategory
    .split(' > ')
    .map((part) => fagmoblerSlugify(part))
    .join('/')}`;
};

export const getCategoryRoot = (
  categoryType: 'articleGroup' | 'categories'
) => {
  if (categoryType === 'articleGroup') return '/produkter';
  if (categoryType === 'categories') return '/rom';
  return '/rom';
};

export const getPathFromAlgoliaSlugs = (
  algoliaProductCategory: string,
  categoryType?: CategoryType
) => {
  if (!algoliaProductCategory || typeof algoliaProductCategory !== 'string')
    return null;
  return `${getCategoryRoot(
    categoryType || 'categories'
  )}/${algoliaProductCategory
    .split(' > ')
    .map((part) => part)
    .join('/')}`;
};

export const getPathFromCategorySlug = (
  algoliaProductCategorySlug: string,
  categoryRoot = 'rom'
) => {
  if (
    !algoliaProductCategorySlug ||
    typeof algoliaProductCategorySlug !== 'string'
  )
    return '/';
  return `/${categoryRoot}/${algoliaProductCategorySlug
    .split(' > ')
    .join('/')}`;
};

export const getFlattenedAlgoliaProductCategories = (
  algoliaProduct: FagmoblerAlgoliaProduct
) => {
  const algoliaCategories = algoliaProduct.categories ?? {};

  return [
    ...new Set(
      Object.keys(algoliaCategories)
        .map((level) =>
          [algoliaCategories[level]].flat().join(' > ').split(' > ')
        )
        .flat()
    ),
  ];
};

export const createBreadcrumbsFromAlgoliaproductAndSlugs = ({
  algoliaProduct,
  slugs,
  motherProduct,
  categoryType,
}: {
  algoliaProduct: FagmoblerAlgoliaProduct;
  slugs: string[];
  motherProduct?: FagmoblerOmniumProduct;
  categoryType?: 'articleGroup' | 'categories';
}): Breadcrumbs => {
  const { categorySlugs, categories } = algoliaProduct;
  if (!categorySlugs || !categories) return [];

  const categoryRoot = getCategoryRoot(categoryType || 'categories');

  // Resolve category from categories and categoryslugs
  const category = createCategoryFromCategorySlugs({
    slugs,
    categories,
    categorySlugs,
  });

  // Resolve categorySlug from categories and categoryslugs
  const categorySlug = createCategorySlugFromCategorySlugs({
    slugs,
    categorySlugs,
  });

  const categoryCrumbs = [
    ...categorySlug
      .filter((s, i) => slugs[i] === s.split(' > ').slice(-1)[0])
      .map((cs, i) => {
        return {
          name: category[i].split(' > ').slice(-1)[0],
          path: `${categoryRoot}/${cs
            .split(' > ')
            .filter((_, j) => i >= j)
            .join('/')}`,
        };
      }),
  ].filter(Boolean) as Breadcrumbs;

  const motherName = motherProduct?.properties.find(
    (prop) => prop.key === 'posterName'
  )?.value;

  const motherCrumb: Breadcrumb | undefined = motherProduct
    ? {
        name: motherName || motherProduct.skuId,
        path: `${categoryCrumbs.slice(-1)[0]?.path}/${motherProduct.skuId}`,
        isCurrent: false,
      }
    : undefined;

  console.log(
    motherName,
    algoliaProduct.posterName,
    algoliaProduct.posterDescription,
    algoliaProduct.objectID
  );

  const name =
    (algoliaProduct.posterName === motherName
      ? algoliaProduct.posterDescription
      : algoliaProduct?.posterName) || algoliaProduct.objectID;

  const productCrumb: Breadcrumb = {
    name,
    path: `${categoryCrumbs.slice(-1)[0]?.path}/${algoliaProduct.objectID}`,
    isCurrent: true,
  };

  const mainCategory = [];

  if (categoryType === 'articleGroup') {
    mainCategory.push({
      name: 'Produkter',
      path: getCategoryRoot(categoryType),
      isCurrent: false,
    });
  }

  if (categoryType === 'categories') {
    mainCategory.push({
      name: 'Rom',
      path: getCategoryRoot(categoryType),
      isCurrent: false,
    });
  }

  if (motherCrumb) {
    return [...mainCategory, ...categoryCrumbs, motherCrumb, productCrumb];
  } else {
    return [...mainCategory, ...categoryCrumbs, productCrumb];
  }
};

export const getSerieNameFromSlug = (slug: string) =>
  slug
    .split('-')
    .join(' ')
    .split('_')
    .join()
    .replace(/\w/, (c) => c.toUpperCase());

export const createCategoryFromCategorySlugs = ({
  categorySlugs,
  categories,
  slugs,
}: {
  categorySlugs: { [key: string]: string | string[] };
  categories: { [key: string]: string | string[] };
  slugs: string[];
}) => {
  const categorySlugsKeys = Object.keys(categorySlugs);
  return categorySlugsKeys
    .map((key, i) => {
      const cat = categories[key];
      const catSlug = categorySlugs[key];
      if (cat && catSlug) {
        const test = slugs.filter((_, j) => i >= j).join(' > ');
        if (typeof cat === 'string') return cat;
        if (typeof cat === 'object' && typeof catSlug === 'object') {
          const slugIndex = catSlug.findIndex((s) => s === test);
          return cat[slugIndex];
        }
      }
    })
    .filter(Boolean)
    .filter((_, i) => i < slugs.length) as string[];
};

export const createHierarchicalMenuFromSlugs = (
  slugs: string[],
  categoryType: CategoryType
): { [key: string]: string[] } => {
  // Example: {'categorySlugs.lvl0': ['stue > sofaer > 3-setere']}
  let hierarchicalMenuFromSlugs: { [key: string]: string[] } = {
    'categorySlugs.lvl0': [slugs.filter(Boolean).join(' > ')],
  };

  if (categoryType === 'articleGroup') {
    hierarchicalMenuFromSlugs = {
      'articleGroupSlugs.lvl0': [slugs.filter(Boolean).join(' > ')],
    };
  }

  return hierarchicalMenuFromSlugs;
};

export const createArticleGroupFromArticleGroupSlugs = ({
  articleGroupSlugs,
  articleGroups,
  slugs,
}: {
  articleGroupSlugs: { [key: string]: string | string[] };
  articleGroups: { [key: string]: string | string[] };
  slugs: string[];
}) => {
  const categorySlugsKeys = Object.keys(articleGroupSlugs);
  return categorySlugsKeys
    .map((key, i) => {
      const cat = articleGroups[key];
      const catSlug = articleGroups[key];
      if (cat && catSlug) {
        const test = slugs.filter((_, j) => i >= j).join(' > ');
        if (typeof cat === 'string') return cat;
        if (typeof cat === 'object' && typeof catSlug === 'object') {
          const slugIndex = catSlug.findIndex((s) => s === test);
          return cat[slugIndex];
        }
      }
    })
    .filter(Boolean)
    .filter((_, i) => i < slugs.length) as string[];
};

export const createCategorySlugFromCategorySlugs = ({
  categorySlugs,
  slugs,
}: {
  categorySlugs: { [key: string]: string | string[] };
  slugs: string[];
}) => {
  const categorySlugsKeys = Object.keys(categorySlugs);
  return categorySlugsKeys
    .map((key, i) => {
      const catSlug = categorySlugs[key];
      if (catSlug) {
        const test = slugs.filter((_, j) => i >= j).join(' > ');
        if (typeof catSlug === 'string') return catSlug;
        if (typeof catSlug === 'object' && typeof catSlug === 'object') {
          const slugIndex = catSlug.findIndex((s) => s === test);
          return catSlug[slugIndex];
        }
      }
    })
    .filter(Boolean)
    .filter((_, i) => i < slugs.length) as string[];
};

export const createArticleGroupSlugFromArticleGroupSlugs = ({
  articleGroupSlugs,
  slugs,
}: {
  articleGroupSlugs: { [key: string]: string | string[] };
  slugs: string[];
}) => {
  const categorySlugsKeys = Object.keys(articleGroupSlugs);
  return categorySlugsKeys
    .map((key, i) => {
      const catSlug = articleGroupSlugs[key];
      if (catSlug) {
        const test = slugs.filter((_, j) => i >= j).join(' > ');
        if (typeof catSlug === 'string') return catSlug;
        if (typeof catSlug === 'object' && typeof catSlug === 'object') {
          const slugIndex = catSlug.findIndex((s) => s === test);
          return catSlug[slugIndex];
        }
      }
    })
    .filter(Boolean)
    .filter((_, i) => i < slugs.length) as string[];
};

export const getLastFacetFragment = (facet?: string) => {
  if (!facet) return null;
  return facet.split(' > ').slice(-1)[0];
};

export const createBreadCrumbsFromCategoryAndCategorySlug = ({
  categorySlugs,
  categories,
  slugs,
  categoryType,
}: {
  categorySlugs: { [key: string]: string | string[] } | null;
  categories: { [key: string]: string | string[] } | null;
  slugs: string[];
  categoryType: CategoryType;
}) => {
  const category =
    categories &&
    categorySlugs &&
    createCategoryFromCategorySlugs({
      categories,
      categorySlugs,
      slugs,
    });
  const categorySlug =
    categorySlugs &&
    createCategorySlugFromCategorySlugs({
      categorySlugs,
      slugs,
    });

  const categoryRootCrumb = {
    name: 'Rom',
    path: getCategoryRoot(categoryType),
  };

  const breadcrumbs = [categoryRootCrumb];

  if (category && categorySlug) {
    categorySlug.forEach((item, i) => {
      const crumb: Breadcrumb = {
        name:
          getLastFacetFragment(category[i]) ||
          getLastFacetFragment(item) ||
          JSON.stringify(item),
        path: getPathFromCategorySlug(item),
        isCurrent: slugs.length - 1 === i,
      };
      return breadcrumbs.push(crumb);
    });
  }

  return breadcrumbs;
};

export const createBreadCrumbsFromArticleGroupsAndArticleGroupSlugs = ({
  articleGroupSlugs,
  articleGroups,
  slugs,
  categoryType,
}: {
  articleGroupSlugs: { [key: string]: string | string[] } | null;
  articleGroups: { [key: string]: string | string[] } | null;
  slugs: string[];
  categoryType: CategoryType;
}) => {
  const articleGroup =
    articleGroups &&
    articleGroupSlugs &&
    createArticleGroupFromArticleGroupSlugs({
      articleGroups,
      articleGroupSlugs,
      slugs,
    });
  const articleGroupSlug =
    articleGroupSlugs &&
    createArticleGroupSlugFromArticleGroupSlugs({
      articleGroupSlugs,
      slugs,
    });
  const categoryRootCrumb = {
    name: 'Produkter',
    path: getCategoryRoot(categoryType),
  };

  const breadcrumbs = [categoryRootCrumb];

  if (articleGroupSlug && articleGroup) {
    articleGroupSlug.map((item, i) => {
      const crumb: Breadcrumb = {
        name:
          getLastFacetFragment(articleGroup[i]) ||
          getLastFacetFragment(item) ||
          JSON.stringify(item),
        path: getPathFromCategorySlug(item),
        isCurrent: slugs.length - 1 === i,
      };
      return breadcrumbs.push(crumb);
    });
  }

  return breadcrumbs;
};
