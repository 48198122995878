import { useEffect, useState, Suspense, useRef } from 'react';
import clsx from 'clsx';
import {
  ArrowLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import turfDistance from '@turf/distance';
import { Point } from 'geojson';
import type { OmniumStore } from '@fagmobler/omnium';

import { defaultRegion } from '@/lib/no-regions';
import { getCartStockStatusForStore } from '@/lib/omnium/utils';
import { useCart } from '@/components/cart';
import dynamic from 'next/dynamic';
import { LocationIcon } from '@fagmobler/ui';

export const MAX_RESULTS = 5;
export const MAX_DISTANCE_KM = 200;

export type OmniumStoreWithStockAndSistance = OmniumStoreWithStock & {
  distance?: number;
};

const StoreMap = dynamic(() => import('@/components/store/StoreMap'), {
  ssr: false,
});

import { useNavigation } from '@/contexts/navigation';

import {
  RegionOption,
  StoreOption,
  useStores,
  useStoresWithStock,
  useStoresByRegion,
  useStoresInRegion,
} from '@/components/store';
import type { Region, OmniumStoreWithStock } from '@/components/store';
import { Input } from '@fagmobler/ui';
import { getInventoryData, useInventory } from '../inventory/useInventory';
import { FagmoblerPublicOmniumProduct } from '@fagmobler/types';

export const getDistance = (
  store: OmniumStoreWithStockAndSistance,
  currentPosition: { lat: number; lng: number }
) => {
  if (!store.longitude || !store.latitude) return null;

  const position: Point = {
    coordinates: [currentPosition.lat, currentPosition.lng],
    type: 'Point',
  };

  const storePosition: Point = {
    coordinates: [parseFloat(store.latitude), parseFloat(store.longitude)],
    type: 'Point',
  };

  return turfDistance(position, storePosition, {
    units: 'kilometers',
  });
};

export function StoreLocatorBackButton({
  children,
  onClick,
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={clsx('m-2', 'flex', 'items-center')} onClick={onClick}>
      <ArrowLeftIcon className={clsx('w-4', 'h-4', 'mr-2')} />
      {children}
    </button>
  );
}

export function StoreLocator({ compact = false }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    cart: { data: cart = {} },
    setStore,
  } = useCart();
  const { setShowStores, setShowStoreStock, selectedProduct } = useNavigation();

  const {
    current: {
      data: {
        store: rawSelectedStore = null,
        region: selectedRegion = defaultRegion,
      } = {},
      refetch,
    },
    stores: { data: rawStores = [] },
  } = useStores();

  const products = [...(cart?.products ?? []), selectedProduct].filter(
    Boolean
  ) as FagmoblerPublicOmniumProduct[];

  const inventoryResult = useInventory({
    products: products ?? [],
  });

  const inventory = getInventoryData(inventoryResult);

  const stores = useStoresWithStock(rawStores, inventory || {});

  const selectedProductInventory = selectedProduct?.skuId
    ? inventory[selectedProduct?.skuId]
    : undefined;

  const [selectedStore] = useStoresWithStock(
    rawSelectedStore ? [rawSelectedStore] : [],
    {}
  );

  const [focusRegion, setFocusRegion] = useState<Region | null>(null);
  const [filteredStores, setFilteredStores] = useState<
    OmniumStoreWithStockAndSistance[] | null
  >(null);
  const [q, setQ] = useState<string>('');
  const [level, setLevel] = useState<'regions' | 'stores'>(
    compact ? 'stores' : 'regions'
  );
  const [currentPosition, setCurrentPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const storesByRegion = useStoresByRegion<OmniumStoreWithStock>(stores);
  const storesInRegion = useStoresInRegion(storesByRegion, focusRegion);
  const [hasGeoLocation, setHasGeoLocation] = useState(false);

  function handleShowRegions() {
    setFocusRegion(null);
    setLevel('regions');
  }

  function handleSetRegion(region: Region) {
    setFocusRegion(region);
    setLevel('stores');
  }

  async function handleSetStore(storeId: OmniumStore['id']) {
    await setStore.mutateAsync(storeId);
    await refetch();

    setShowStores(false);
    setShowStoreStock({ visible: false, inventory: null });
  }

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;
    setQ(q);
  };

  useEffect(() => {
    if ('geolocation' in navigator) {
      setHasGeoLocation(true);
    }
  }, []);

  useEffect(() => {
    if (!currentPosition) {
      return;
    }

    const { lat, lng } = currentPosition;
    const storesByDistance: OmniumStoreWithStockAndSistance[] = stores
      .filter((store) => store.latitude && store.longitude)
      .map((store) => ({
        ...store,
        distance: getDistance(store, { lat, lng }) || undefined,
      }))
      .sort((a, b) => {
        if (!a.distance || !b.distance) return -1;
        return a.distance > b.distance ? 1 : -1;
      });

    setFilteredStores(storesByDistance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition]);

  const handleLoactionClick = () => {
    if (!navigator.geolocation) {
      alert('Beklager, vi klarer ikke å finne din lokasjon');
      return null;
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const crd = pos.coords;
        const lat = crd.latitude; //62.251218730599035;
        const lng = crd.longitude; // 8.237158402050145;

        setCurrentPosition({ lat, lng });
        setLevel('stores');
        setQ('');
      },
      (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      },
      {
        enableHighAccuracy: true,
      }
    );
  };

  const storesByDistance = filteredStores
    ? filteredStores
        .filter((store) => store.latitude && store.longitude)
        .filter((store) => store.distance && store.distance < MAX_DISTANCE_KM)
    : null;

  const mapStores =
    focusRegion && !currentPosition
      ? focusRegion
        ? storesInRegion
        : stores
      : storesByDistance?.slice(0, MAX_RESULTS) || stores;

  return (
    <>
      {!compact && level && (
        <div className={clsx('w-full', 'aspect-[16/9]')}>
          <Suspense fallback="...loading">
            <StoreMap
              stores={mapStores}
              setStore={handleSetStore}
              center={currentPosition || undefined}
              showControls={false}
              currentLocation={currentPosition || undefined}
              inputRef={inputRef}
              onAutocomplete={(lat, lng) => {
                setCurrentPosition({ lat, lng });
                setFocusRegion(null);
                setLevel('stores');
              }}
            />
          </Suspense>
        </div>
      )}

      {!compact && (
        <div className="mx-5">
          <h2 className="hd-lg my-5">Våre butikker</h2>
          <div className="flex justify-between gap-3">
            <Input
              name="q"
              value={q}
              placeholder="Søk etter sted eller postnummer"
              onChange={handleQueryChange}
              icon={<MagnifyingGlassIcon className="w-6 h-6 text-neutral-80" />}
              inputClassName="bg-neutral-40 text-neutral-80 border-none"
              className="my-0 w-full"
              ref={inputRef}
              resetField={() => {
                setFilteredStores(null);
                setFocusRegion(null);
                setLevel('regions');
                setCurrentPosition(null);
                setQ('');
              }}
            />
            <button
              onClick={handleLoactionClick}
              disabled={!hasGeoLocation}
              className="flex justify-center items-center rounded-md bg-neutral-70 w-16 h-14 my-2 text-neutral-0"
              title="Bruk min lokasjon"
            >
              <LocationIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      )}

      <div className={clsx('p-6')}>
        {level === 'regions' && (
          <ul>
            {Object.values(storesByRegion)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((region) => (
                <li key={region.iso}>
                  <RegionOption label={region.name} onSelect={() => handleSetRegion(region)} />
                </li>
              ))}
          </ul>
        )}
        {level === 'stores' && (
          <>
            {!filteredStores && (
              <StoreLocatorBackButton onClick={handleShowRegions}>
                Vis alle fylker
              </StoreLocatorBackButton>
            )}
            <ul>
              {(mapStores || storesInRegion).map((store) => {
                const stock =
                  store.id && selectedProductInventory
                    ? selectedProductInventory[store.id]
                    : undefined;
                return (
                  <li key={store.id}>
                    <StoreOption
                      storeHasCartInStock={getCartStockStatusForStore(
                        cart,
                        store,
                        inventory
                      )}
                      stock={stock}
                      store={store}
                      isSelected={store.id === selectedStore?.id}
                      onSelectStore={() => handleSetStore(store.id!)}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </>
  );
}

export default StoreLocator;
