import { Badge } from '@fagmobler/ui';
import clsx from 'clsx';

type StoreHasCartInStockIndicatorProps = {
  storeHasCartInStock?: boolean;
};

const StoreHasCartInStockIndicator = ({
  storeHasCartInStock,
}: StoreHasCartInStockIndicatorProps) => {
  if (typeof storeHasCartInStock === 'undefined') return null;
  return (
    <div className="cap-xs text-neutral-60 my-4 flex items-center">
      {storeHasCartInStock ? (
        <>
          <Badge size="xs" variant="successDark" className={clsx('mr-1')} />
          Butikken har hele handlekurven din på lager
        </>
      ) : (
        <>
          <Badge size="xs" variant="outlineNeutral" className={clsx('mr-1')} />
          Butikken har ikke hele handlekurven din på lager
        </>
      )}
    </div>
  );
};

export default StoreHasCartInStockIndicator;
