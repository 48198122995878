import { useNavigation } from '@/contexts/navigation';
import { useCart } from '@/components/cart';
import clsx from 'clsx';
import { Badge } from '@fagmobler/ui';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

export const CartButton = () => {
  const { setShowCart } = useNavigation();
  const { cart: { data: { orderForm: { lineItems = [] } = {} } = {} } = {} } = useCart();

  const lineItemsByPackage = lineItems?.reduce((acc, item) => {
    if (item.isPackage) {
      const hasItemsInAcc = acc.find(
        (i) => i.packageLineItemId === item.packageLineItemId
      );
      if (hasItemsInAcc) return acc;
    }

    return [...acc, item];
  }, [] as typeof lineItems);

  const orderQuantity = lineItemsByPackage?.length || 0;

  return (
    <button
      className={clsx(
        'flex items-center relative',
        'md:gap-2 py-2 mr-2 md:pl-3 md:mr-0',
        'focus:outline-none ring-offset-focus focus-visible:ring-4'
      )}
      title="handlekurv"
      onClick={() => setShowCart()}
    >
      {orderQuantity > 0 && (
        <Badge size="sm" className="hidden md:flex absolute w-[22px] h-[22px] -right-[14px] -top-[2px] items-center min-w-auto min-h-auto">
          {orderQuantity}
        </Badge>
      )}
      {orderQuantity > 0 && (
        <span className="block md:hidden w-2 h-2 bg-primary-50 aspect-square rounded-full absolute top-1 -right-1"></span>
      )}
      <ShoppingCartIcon className="h-5 w-5" />
    </button>
  );
};
