import { useQuery, useQueryClient } from 'react-query';
import type { OmniumPaymentSettings } from '@fagmobler/omnium';

async function fetchPaymentSettings(): Promise<OmniumPaymentSettings> {
  const res = await fetch(`/api/cart/payment-settings`, {
    method: 'POST',
  });

  if (res.status !== 200) return {};
  // console.log('paymentSettings', { res });

  const data = (await res.json()) as OmniumPaymentSettings;
  // console.log('paymentSettings', { data });

  return data;
}

export function usePaymentSettings() {
  const queryClient = useQueryClient();

  // const cartId = queryClient.getQueryData<OmniumCart>('cart')?.id || null;

  return {
    paymentSettings: useQuery<OmniumPaymentSettings>(
      'paymentSettings',
      () => fetchPaymentSettings(),
      {
        // initialData
      }
    ),
  };
}
