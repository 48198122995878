import { useMutation, useQueryClient } from 'react-query';
import type { FagmoblerOmniumCart } from '@fagmobler/types';
import type { OmniumCart, OmniumOrderLine } from '@fagmobler/omnium';

export async function addItemToCart(item: OmniumOrderLine) {
  const res = await fetch('/api/cart/items/add', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      items: [item],
    }),
  });

  return (await res.json()) as FagmoblerOmniumCart;
}

export function useAddItemToCart() {
  const queryClient = useQueryClient();

  return useMutation<OmniumCart, unknown, OmniumOrderLine>(
    (item) => {
      return addItemToCart(item);
    },
    {
      onMutate: async (item) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['cart', item.code]);

        // Snapshot the previous value
        const previousItem = queryClient.getQueryData(['cart', item.code]);

        // Optimistically update to the new value
        queryClient.setQueryData(['cart', item.code], item);

        // Return a context with the previous and new todo
        return { previousItem, item };
      },
      onSuccess: (newCart) => {
        // ✅ update detail view directly
        if (newCart.id) {
          window.localStorage.setItem('cartId', newCart.id);
        }
        queryClient.setQueryData('cart', {
          ...newCart,
        });
      },
    }
  );
}
