import { useMutation, useQueryClient } from 'react-query';
import type { FagmoblerOmniumCart } from '@fagmobler/types';
import type { OmniumCart, OmniumOrderLine } from '@fagmobler/omnium';

async function updateOrderlineQuantity(orderLine: OmniumOrderLine) {
  // console.log('updateOrderlineQuantity', orderLine);

  const res = await fetch('/api/cart/items/update', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      lineItemId: orderLine.lineItemId,
      quantity: orderLine.quantity,
    }),
  });
  // console.log('res from addItemToCart', res);

  const data = (await res.json()) as FagmoblerOmniumCart;
  // console.log('data from addItemToCart', data);

  return data;
}

export function useUpdateCartItem() {
  const queryClient = useQueryClient();

  return useMutation<OmniumCart, FagmoblerOmniumCart, OmniumOrderLine>(
    (orderLine) => {
      // console.log('mutate', { orderLine });
      return updateOrderlineQuantity(orderLine);
    },
    {
      onMutate: async (orderLine) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(['cart']);

        // Snapshot the previous value
        const oldCart = queryClient.getQueryData<OmniumCart>(['cart']);

        // Optimistically update to the new value
        queryClient.setQueryData<OmniumCart>(['cart'], (old) => {
          // todo calculate new cart

          return {
            ...old,
            orderForm: {
              ...old?.orderForm,
              lineItems: old?.orderForm?.lineItems?.map((oldLineItem) => {
                if (oldLineItem.lineItemId === orderLine.lineItemId) {
                  return {
                    ...oldLineItem,
                    quantity: orderLine.quantity,
                    discountedPrice:
                      ((oldLineItem?.discountedPrice || 1) /
                        (oldLineItem?.quantity || 1)) *
                      (orderLine.quantity || 0),
                  };
                }

                return oldLineItem;
              }),
            },
          };
        });

        // Return a context with the previous and new todo
        return { oldCart };
      },
      onSuccess: (newCart) => {
        // ✅ update detail view directly
        if (newCart.id) {
          window.localStorage.setItem('cartId', newCart.id);
        }
        queryClient.setQueryData('cart', {
          ...newCart,
        });
      },
    }
  );
}
