import { useQuery, useQueryClient } from 'react-query';
import type { OmniumStore } from '@fagmobler/omnium';
import { defaultRegion } from '@/lib/no-regions';
import { HOST } from '@/lib/host';

export type Region = {
  iso: string;
  name: string;
  city?: string | null;
};

export type CurrentStore = {
  store: OmniumStore | null;
  region: Region | null;
};

export async function fetchCurrentStore(): Promise<CurrentStore> {
  const res = await fetch(`${HOST}/api/stores/current`);

  if (res.status !== 200) return { store: null, region: null } as CurrentStore;
  // console.log('currentStore', { res });

  const data = (await res.json()) as CurrentStore;
  // console.log('currentStore', { data });

  return data;
}

export async function fetchStores(): Promise<OmniumStore[]> {
  const res = await fetch(`${HOST}/api/stores`);
  // console.log('stores', { res });

  if (res.status !== 200) return [];

  const data = (await res.json()) as OmniumStore[];
  // console.log('stores', { data });

  return data;
}

export type UseStoresProps = {
  initialData?: {
    stores?: OmniumStore[];
    store?: OmniumStore | null;
    region?: Region | null;
  };
};

export function useStores(
  {
    initialData: { stores = [], store = null, region = defaultRegion } = {},
  }: UseStoresProps = { initialData: {} }
) {
  useQueryClient();
  return {
    stores: useQuery<OmniumStore[]>('stores', () => fetchStores(), {
      initialData: stores,
    }),
    current: useQuery<CurrentStore>('currentStore', () => fetchCurrentStore(), {
      initialData: { store, region },
    }),
  };
}

export default useStores;
